const defaultUser = {
    id: null,
    fname: '',
    lname: '',
    email: '',
    mobile_no: '',
    department: '',
    company: '',
    education: '',
    work_history: '',
    languages: '',
    loc_proximity: null,
    profile_picture: null,
    profile_banner: null,
    member_type: '',
    is_supplier: false,
    role: '',
    date_joined: '0000-00-00',
    email_verified_at: null,
    created_at: null,
    updated_at: null,
    deleted_at: null,
};

export default defaultUser;
