import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { Box, Button, Card, CardHeader, IconButton, useMediaQuery, useTheme } from '@mui/material';
import Iconify from 'components/iconify/Iconify';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import api from 'services/api';
import { enqueueSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

function CustomImageItem({ item, fetchUser }) {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const { currentUser } = useSelector((state) => state.currentUser);

    async function removeImage(id) {
        setLoading(true);

        try {
            const response = await api.destroy(`/api/gallery/${id}`);
            enqueueSnackbar(response.data.message, { variant: 'success' });
            fetchUser();
        } catch (error) {
            console.log(error);
            enqueueSnackbar(error.response.data.message, { variant: 'error' });
        }

        setLoading(false);
    }

    return (
        <ImageListItem
            key={item.id}
            style={{ borderRadius: 5, overflow: 'hidden' }}
            onMouseOver={() => setShow(true)}
            onMouseOut={() => setShow(false)}
        >
            <img
                // src={`${item.img}?w=248&fit=crop&auto=format`}
                src={item.image}
                // srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                srcSet={item.image}
                alt={item.title}
                loading="lazy"
            />
            {show && (
                <ImageListItemBar
                    sx={{
                        background:
                            'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                    }}
                    title={item.title}
                    subtitle={item.author}
                    actionIcon={
                        <LoadingButton
                            loading={loading}
                            onClick={() => removeImage(item.id)}
                            sx={{ color: 'white' }}
                            aria-label={`info about ${item.title}`}
                        >
                            <Iconify icon={'material-symbols:delete-outline-rounded'} />
                        </LoadingButton>
                    }
                    position="top"
                    actionPosition="right"
                />
            )}
            {/* <ImageListItemBar
                title={item.title}
                subtitle={<span>by: {item.author}</span>}
                position="below"
                sx={{ textTransform: 'capitalize' }}
            /> */}
        </ImageListItem>
    );
}

export default function ProfileGallery({ itemData, fetchUser, showAdd }) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    const [loading, setLoading] = useState(false);

    async function onSelectFile(event) {
        setLoading(true);

        const form = new FormData();

        Array.from(event.target.files).forEach((file) => {
            form.append('fileName[]', file);
        });

        console.log([...form]);

        try {
            const response = await api.upload('/api/gallery', form);
            enqueueSnackbar(response.data.message, { variant: 'success' });
            await fetchUser();
        } catch (error) {
            console.log(error);
            enqueueSnackbar(error.response.data.message, { variant: 'error' });
        }

        setLoading(false);
    }

    return (
        <Card>
            <CardHeader
                title="Gallery"
                action={
                    showAdd && (
                        <LoadingButton
                            loading={loading}
                            loadingPosition="start"
                            color="inherit"
                            startIcon={<Iconify icon={'solar:gallery-add-bold'} />}
                            component="label"
                        >
                            Add Image
                            <input hidden accept="image/*" type="file" multiple onChange={onSelectFile} />
                        </LoadingButton>
                    )
                }
            />
            <Box sx={{ m: 3 }}>
                <ImageList sx={{ width: '100%' }} cols={matches ? 1 : 2} gap={20}>
                    {itemData.map((item) => (
                        <CustomImageItem item={item} key={item.id} fetchUser={fetchUser} />
                    ))}
                </ImageList>
            </Box>
        </Card>
    );
}
