import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentUser: {
        id: null,
        fname: '',
        lname: '',
        email: '',
        mobile_no: '',
        department: null,
        company: '',
        education: null,
        work_history: null,
        languages: null,
        loc_proximity: null,
        profile_picture: null,
        profile_banner: null,
        member_type: '',
        is_supplier: null,
        role: '',
        date_joined: null,
        email_verified_at: null,
        created_at: null,
        updated_at: null,
        deleted_at: null,
        projects: [],
        galleries: [],
    },
};

export const currentUser = createSlice({
    name: 'curent-user',
    initialState,
    reducers: {
        setCurrentUser: (state, action) => {
            state.currentUser = action.payload;
        },
    },
});

export const { setCurrentUser } = currentUser.actions;

export default currentUser.reducer;
