import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    InputLabel,
    Stack,
    TextField,
    Typography,
    Container,
    IconButton,
    InputAdornment,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import api from 'services/api';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew';
import Iconify from 'components/iconify/Iconify';
import { useSnackbar } from 'notistack';

const delay = (ms) => new Promise((r) => setTimeout(r, ms));

export default function ChangePassword() {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const [password, setPassword] = useState('');

    const [confirmPassword, setConfirmPassword] = useState('');

    const [showPassword, setShowPassword] = useState(false);

    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    function handleBack() {
        navigate(-1);
    }

    async function handleUpdate() {
        setLoading(true);

        const form = {
            password,
            password_confirmation: confirmPassword,
        };

        try {
            const response = await api.post('/api/auth/update-user-password', form);
            enqueueSnackbar(response.data.message, { variant: 'success' });
            navigate('/dashboard/profile');
        } catch (error) {
            console.log(error);
            if (error.response.data.password) {
                error.response.data.password.forEach((passwordError) => {
                    enqueueSnackbar(passwordError, { variant: 'error' });
                });
            } else {
                enqueueSnackbar('Something went wrong. Please try again later.', { variant: 'error' });
            }
        }

        setLoading(false);
    }

    return (
        <>
            <Helmet>
                <title>Go Virtual Members: Change Password</title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="flex-start" mb={5}>
                    <IconButton color="primary" onClick={handleBack}>
                        <ArrowBackIosNew />
                    </IconButton>
                    <Typography variant="h4">Change Password</Typography>
                </Stack>
                <Card>
                    <CardHeader title="Setup new password" />
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Stack>
                                    <InputLabel>New Password</InputLabel>
                                    <TextField
                                        name="password"
                                        placeholder="New Password"
                                        variant="outlined"
                                        type={showPassword ? 'text' : 'password'}
                                        inputProps={{ name: 'password' }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => setShowPassword(!showPassword)}
                                                        edge="end"
                                                    >
                                                        <Iconify
                                                            icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack>
                                    <InputLabel>Confirm Password</InputLabel>
                                    <TextField
                                        id="pass2"
                                        placeholder="Confirm Password"
                                        variant="outlined"
                                        required
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        inputProps={{ name: 'pass2' }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                        edge="end"
                                                    >
                                                        <Iconify
                                                            icon={
                                                                showConfirmPassword
                                                                    ? 'eva:eye-fill'
                                                                    : 'eva:eye-off-fill'
                                                            }
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions sx={{ p: 3 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <Button variant="outlined" onClick={handleBack}>
                                Cancel
                            </Button>
                            <LoadingButton
                                loading={loading}
                                disabled={loading}
                                variant="contained"
                                loadingPosition="start"
                                startIcon={<Iconify icon="uil:pen" />}
                                onClick={handleUpdate}
                            >
                                Update
                            </LoadingButton>
                        </Box>
                    </CardActions>
                </Card>
            </Container>
        </>
    );
}
