import { useState } from 'react';
// @mui
import { Menu, Button, MenuItem, Typography } from '@mui/material';
// component
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const SORT_BY_OPTIONS = [
    { value: 'desc', label: 'Newest' },
    { value: 'alphabetical', label: 'Alphabetically' },
    // { value: 'priceDesc', label: 'Price: High-Low' },
    // { value: 'priceAsc', label: 'Price: Low-High' },
];

export default function ShopProductSort({ sort, open, setOpen, onSelect }) {
    // const [open, setOpen] = useState(null);

    function handleOpen(event) {
        setOpen(event.currentTarget);
    }

    function handleClose() {
        setOpen(null);
    }

    function getSortLabel() {
        for (let i = 0; i < SORT_BY_OPTIONS.length; i++) {
            const sortOption = SORT_BY_OPTIONS[i];
            if (sortOption.value == sort) {
                return sortOption.label;
            }
        }

        return '';
    }

    return (
        <>
            <Button
                color="inherit"
                disableRipple
                onClick={handleOpen}
                endIcon={<Iconify icon={open ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'} />}
            >
                Sort By:&nbsp;
                <Typography component="span" variant="subtitle2" sx={{ color: 'text.secondary' }}>
                    {getSortLabel()}
                </Typography>
            </Button>
            <Menu
                keepMounted
                anchorEl={open}
                open={Boolean(open)}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {SORT_BY_OPTIONS.map((option) => (
                    <MenuItem
                        key={option.value}
                        selected={option.value === 'newest'}
                        onClick={() => onSelect(option)}
                        sx={{ typography: 'body2' }}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}
