// @mui
import PropTypes from 'prop-types';
import { Card, Typography, CardHeader, CardContent, CardActions, Button, Divider, Box } from '@mui/material';
import {
    Timeline,
    TimelineDot,
    TimelineItem,
    TimelineContent,
    TimelineSeparator,
    TimelineConnector,
    treeItemClasses,
} from '@mui/lab';
// icons
import Iconify from 'components/iconify';
import FastfoodIcon from '@mui/icons-material/FastfoodTwoTone';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
// utils
import { fDateTime } from '../../../utils/formatTime';

// ----------------------------------------------------------------------

AppInfo.propTypes = {
    title: PropTypes.string,
    subheader: PropTypes.string,
    list: PropTypes.array.isRequired,
    handleUpdate: PropTypes.func,
    showUpdate: PropTypes.bool,
};

export default function AppInfo({ title, subheader, list, handleUpdate, showUpdate, ...other }) {
    return (
        <Card {...other}>
            <CardHeader title={title} subheader={subheader} />

            <CardContent
                sx={{
                    '& .MuiTimelineItem-missingOppositeContent:before': {
                        display: 'none',
                    },
                }}
            >
                <Timeline>
                    {list.map((item, index) => (
                        <OrderItem key={item.id} item={item} isLast={index === list.length - 1} />
                    ))}
                </Timeline>
            </CardContent>
            {showUpdate &&
                <>
                    <Divider />

                    <Box sx={{ p: 2, textAlign: 'right' }}>
                        <Button onClick={handleUpdate} size="small" color="inherit" startIcon={<Iconify icon={'uil:pen'} />}>
                            Update
                        </Button>
                    </Box>
                </>
            }
        </Card>
    );
}

// ----------------------------------------------------------------------

OrderItem.propTypes = {
    isLast: PropTypes.bool,
    item: PropTypes.shape({
        // time: PropTypes.instanceOf(Date),
        info: PropTypes.string,
        title: PropTypes.string,
        type: PropTypes.string,
        icon: PropTypes.object,
    }),
};

function OrderItem({ item, isLast }) {
    const { type, title, info, icon } = item;
    return (
        <TimelineItem>
            <TimelineSeparator>
                <TimelineDot
                // color={
                //     (type === 'order1' && 'primary') ||
                //     (type === 'order2' && 'success') ||
                //     (type === 'order3' && 'info') ||
                //     (type === 'order4' && 'warning') ||
                //     'error'
                // }
                >
                    {icon}
                </TimelineDot>
                {isLast ? null : <TimelineConnector />}
            </TimelineSeparator>

            <TimelineContent>
                <Typography variant="subtitle2">{info}</Typography>

                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    {/* {fDateTime(time)} */}
                    {title}
                </Typography>
            </TimelineContent>
        </TimelineItem>
    );
}
