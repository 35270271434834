import PropTypes from 'prop-types';
// @mui
import { Avatar, Box, Card, CardMedia, Stack, Typography } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
// mock
import account from '../../../_mock/account';

const StyledAccount = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    position: 'absolute',
    bottom: 0,
    left: 0,
    // borderRadius: Number(theme.shape.borderRadius) * 1.5,
    // backgroundColor: alpha(theme.palette.grey[500], 0.12),
    zIndex: 1,
}));

export default function ProfileBanner() {
    return (
        <Card sx={{ height: 200 }}>
            <CardMedia
                component="img"
                sx={{ filter: 'brightness(80%)', objectFit: 'cover', height: '100%' }}
                image="/assets/images/covers/cover_1.jpg"
            />
            <StyledAccount>
                <Avatar
                    sx={{ width: 70, height: 70, border: 2, borderColor: 'white' }}
                    src={account.photoURL}
                    alt="photoURL"
                />

                <Box sx={{ ml: 2 }}>
                    <Typography variant="h6" sx={{ color: 'white' }}>
                        Juan Dela Cruz
                    </Typography>

                    <Typography variant="body2" sx={{ color: '#d9d9d9' }}>
                        Real Estate Manager
                    </Typography>
                </Box>
            </StyledAccount>
        </Card>
    );
}
