import { ArrowBackIos, ArrowBackIosNew } from '@mui/icons-material';
import { Container, IconButton, Stack, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { BlogPostUpdate } from 'sections/@dashboard/blog';
import api from 'services/api';

export default function PostEdit() {
    const navigate = useNavigate();

    const { slug } = useParams();

    const [post, setPost] = useState();

    useEffect(() => {
        fetchPost();
    }, []);

    async function fetchPost() {
        try {
            const response = await api.get(`/api/posts/${slug}`);
            console.log(response.data);
            setPost(response.data.post);
        } catch (error) {
            console.log(error);
        }
    }

    function handleBack() {
        navigate(-1);
    }

    return (
        <>
            <Helmet>
                <title>Go Virtual Members: Edit Post</title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="flex-start" mb={5}>
                    <IconButton color="primary" onClick={handleBack}>
                        <ArrowBackIosNew />
                    </IconButton>
                    <Typography variant="h4">
                        Edit Post
                    </Typography>
                </Stack>
                {post && <BlogPostUpdate post={post} />}
            </Container>
        </>
    );
}
