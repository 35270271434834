import { ArrowBackIos, ArrowBackIosNew } from '@mui/icons-material';
import { Container, IconButton, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { UserFormUpdate } from 'sections/@dashboard/user';

export default function UserPageEdit() {
    const navigate = useNavigate();
    const { id } = useParams();

    function handleBack() {
        navigate(-1);
    }

    return (
        <>
            <Helmet>
                <title>Go Virtual Members: Edit Member</title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="flex-start" mb={5}>
                    <IconButton color="primary" onClick={handleBack}>
                        <ArrowBackIosNew />
                    </IconButton>
                    <Typography variant="h4">
                        Edit Member
                    </Typography>
                </Stack>
                <UserFormUpdate id={id} />
            </Container>
        </>
    );
}
