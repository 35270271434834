import { useState } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { Button, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import api from 'services/api';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import MessageModal from './MessageModal';
import DisconnectModal from './DisconnectModal';

function ConnectionCard({ connection, fetchUser, setOpenMessage, setSelectedUser, setOpenDisconnect }) {
    const { currentUser } = useSelector((state) => state.currentUser);

    const [loadingApprove, setLoadingApprove] = useState(false);
    const [loadingDecline, setLoadingDecline] = useState(false);

    const navigate = useNavigate();

    async function handleApprove() {
        setLoadingApprove(true);

        try {
            const response = await api.post(`/api/users/confirm-connect/${connection.id}`, { confirm: true });
            enqueueSnackbar(response.data.message, { variant: 'success' });
            await fetchUser();
        } catch (error) {
            console.log(error);
            enqueueSnackbar('Something went wrong. Please try again later', { variant: 'error' });
        }

        setLoadingApprove(false);
    }

    async function handleDecline() {
        setLoadingDecline(true);

        try {
            const response = await api.post(`/api/users/confirm-connect/${connection.id}`, { confirm: false });
            enqueueSnackbar(response.data.message, { variant: 'success' });
            await fetchUser();
        } catch (error) {
            console.log(error);
            enqueueSnackbar('Something went wrong. Please try again later', { variant: 'error' });
        }

        setLoadingDecline(false);
    }

    async function handleSendMessage() {
        try {
            const response = await api.get(`/api/messages/recipient/${connection.id}`);
            navigate(`/dashboard/inbox/${response.data[0].id}`);
            return;
        } catch (error) {
            console.log(error);
        }

        // show new message modal
        setSelectedUser(connection);
        setOpenMessage(true);
    }

    function handleDisconnect() {
        setSelectedUser(connection);
        setOpenDisconnect(true);
    }

    return (
        <Grid item xs={12} md={6} lg={4}>
            <Card sx={{ paddingBottom: 2 }}>
                <CardHeader
                    avatar={
                        <Avatar
                            sx={{ width: 56, height: 56 }}
                            src={`${process.env.REACT_APP_API_URL}/storage/${connection.profile_picture}`}
                        />
                    }
                    action={
                        connection.pivot.confirmed == true && (
                            <IconButton onClick={handleDisconnect} aria-label="settings" variant="contained">
                                <CloseIcon />
                            </IconButton>
                        )
                    }
                    title={`${connection.fname} ${connection.lname}`}
                    subheader={connection.company}
                />
                <CardActions sx={{ pl: 3, pt: 2 }}>
                    {connection.pivot.confirmed == true && (
                        <Button onClick={handleSendMessage} variant="outlined">
                            Message
                        </Button>
                    )}
                    {connection.pivot.confirmed == false && connection.pivot.sender_id == currentUser.id && (
                        <Button variant="outlined" color="error">
                            Cancel Request
                        </Button>
                    )}
                    {connection.pivot.confirmed == false && connection.pivot.sender_id != currentUser.id && (
                        <>
                            <LoadingButton loading={loadingApprove} onClick={handleApprove} variant="outlined">
                                Approve
                            </LoadingButton>
                            <LoadingButton
                                loading={loadingDecline}
                                onClick={handleDecline}
                                variant="outlined"
                                color="error"
                            >
                                Decline
                            </LoadingButton>
                        </>
                    )}
                </CardActions>
            </Card>
        </Grid>
    );
}

export default function ProfileConnections({ connections, fetchUser }) {
    const [selectedUser, setSelectedUser] = useState();

    const [openMessage, setOpenMessage] = useState(false);

    const [openDisconnect, setOpenDisconnect] = useState(false);

    return (
        <>
            {selectedUser && (
                <>
                    <DisconnectModal
                        open={openDisconnect}
                        setOpen={setOpenDisconnect}
                        user={selectedUser}
                        fetchUser={fetchUser}
                    />
                    <MessageModal open={openMessage} setOpen={setOpenMessage} recipient={selectedUser} />
                </>
            )}
            <Grid container spacing={3} sx={{ padding: 3 }}>
                {connections.map((conn, index) => (
                    <ConnectionCard
                        connection={conn}
                        key={index}
                        fetchUser={fetchUser}
                        setOpenMessage={setOpenMessage}
                        setSelectedUser={setSelectedUser}
                        setOpenDisconnect={setOpenDisconnect}
                    />
                ))}
            </Grid>
        </>
    );
}
