import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';
import api from 'services/api';

export default function DisconnectModal({ open, setOpen, user, fetchUser }) {

    const [loading, setLoading] = useState(false);

    function handleClose() {
        setOpen(false);
    }

    async function handleDisconnect() {
        setLoading(true);

        try {
            const response = await api.destroy(`/api/users/disconnect/${user.id}`);
            await fetchUser();
            enqueueSnackbar(response.data.message, { variant: 'success' });
            setOpen(false);
        } catch (error) {
            console.log(error);
            enqueueSnackbar('Something went wrong. Please try again later.', { variant: 'error' });
        }
        
        setLoading(false);
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Remove Connection</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to disconnect with {user.fname} {user.lname}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined">Cancel</Button>
                    <LoadingButton loading={loading} onClick={handleDisconnect} variant="contained" color="error">
                        Disconnect
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}
