import { useState, useEffect } from 'react';
import { sample } from 'lodash';
import api from 'services/api';
import { useSelector } from 'react-redux';

export function useSupplierList(sort) {
    const [users, setUsers] = useState([]);

    const { currentUser } = useSelector((state) => state.currentUser);

    useEffect(() => {
        fetchUsers();
    }, [sort]);

    async function fetchUsers() {
        let response = null;

        try {
            response = await api.get(`/api/users/suppliers/${sort}`);
            console.log('response', response.data);
        } catch (error) {
            console.log(error);
            return;
        }

        const _users = response.data.suppliers.map((user, index) => ({
            id: user.id,
            avatar: `${process.env.REACT_APP_API_URL}/storage/${user.profile_picture}`,
            cover: user.profile_banner ? `${process.env.REACT_APP_API_URL}/storage/${user.profile_banner}` : '/assets/images/covers/cover_default.jpg',
            name: `${user.fname} ${user.lname}`,
            shortDesc: `${user.department} | ${user.company}`,
            status: '',
            isSupplier: user.is_supplier,
            connections: user.friends,
            connectionStatus: checkConnection(user.friends)
        }));

        setUsers(_users);
    }

    function checkConnection(friends) {
        if (friends.length > 0) {
            for (let i = 0; i < friends.length; i++) {
                const friend = friends[i];
                
                if (friend.id == currentUser.id) {
                    return friend.pivot.confirmed ? 'connected' : 'pending';
                }
            }
        }

        return 'not_connected';
    }

    return users;
}
