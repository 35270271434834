import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
//
import api from 'services/api';
import { useDispatch } from 'react-redux';
import { setCurrentUser } from 'store/current-user';

import Nav from './nav';
import Header from './header';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 24,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
        paddingLeft: theme.spacing(2),
        paddingTop: APP_BAR_DESKTOP + 24,
        paddingRight: theme.spacing(2),
    },
}));

const MainInbox = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%'
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        detectLogin();
    }, []);

    async function detectLogin() {
        console.log('checking if logged in...');

        const token = localStorage.getItem('token');

        try {
            const response = await api.get('/api/auth/user-profile');
            dispatch(setCurrentUser(response.data.user));
        } catch (error) {
            console.log(error);
            navigate('/login');
            return;
        }

        if (token == null) {
            console.log('invalid token, logging out...');
            navigate('/login');
        } else {
            setLoggedIn(true);
        }
    }

    function isInbox() {
        return location.pathname.includes('/dashboard/inbox');
    }

    if (loggedIn) {
        return (
            <StyledRoot>
                {!isInbox() &&
                    <Header onOpenNav={() => setOpen(true)} />
                }

                <Nav openNav={open} onCloseNav={() => setOpen(false)} />

                {isInbox() ? (
                    <MainInbox>
                        <Outlet />
                    </MainInbox>
                ) : (
                    <>
                        <Main>
                            <Outlet />
                        </Main>
                    </>
                )}
            </StyledRoot>
        );
    }

    return <div />;
}
