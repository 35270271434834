import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from 'layouts/dashboard';
import SimpleLayout from 'layouts/simple';
//
import UserPage from 'pages/UserPage';
import LoginPage from 'pages/LoginPage';
import Page404 from 'pages/Page404';
import HomePage from 'pages/HomePage';
import MembersPage from 'pages/MembersPage';
import SuppliersPage from 'pages/SuppliersPage';
import ProfilePage from 'pages/ProfilePage';
import InboxPage from 'pages/InboxPage';
import UserPageAdd from 'pages/UserPageAdd';
import PostView from 'pages/PostView';
import PostAdd from 'pages/PostAdd';
import UserPageEdit from 'pages/UserPageEdit';
import ProfilePageEdit from 'pages/ProfilePageEdit';
import ProjectAdd from 'pages/ProjectAdd';
import MemberPageView from 'pages/MemberPageView';
import SupplierPageView from 'pages/SupplierPageView';
import ChangePassword from 'pages/ChangePassword';
import PostEdit from 'pages/PostEdit';

// ----------------------------------------------------------------------

export default function Router() {
    const routes = useRoutes([
        {
            path: '/dashboard',
            element: <DashboardLayout />,
            children: [
                { element: <Navigate to="/dashboard/home" />, index: true },
                { path: 'post/add', element: <PostAdd /> },
                { path: 'post/edit/:slug', element: <PostEdit /> },
                { path: 'post/:slug', element: <PostView /> },
                { path: 'manage-members', element: <UserPage /> },
                { path: 'manage-members/add', element: <UserPageAdd /> },
                { path: 'manage-members/edit/:id', element: <UserPageEdit /> },
                { path: 'home', element: <HomePage /> },
                { path: 'members', element: <MembersPage /> },
                { path: 'members/:id', element: <MemberPageView /> },
                { path: 'suppliers', element: <SuppliersPage /> },
                { path: 'suppliers/:id', element: <SupplierPageView /> },
                { path: 'profile', element: <ProfilePage /> },
                { path: 'profile/edit', element: <ProfilePageEdit /> },
                { path: 'project/add', element: <ProjectAdd /> },
                { path: 'inbox/:id', element: <InboxPage /> },
                { path: 'change-password', element: <ChangePassword /> }
            ],
        },
        {
            path: 'login',
            element: <LoginPage />,
        },
        {
            element: <SimpleLayout />,
            children: [
                { element: <Navigate to="/dashboard/home" />, index: true },
                { path: '404', element: <Page404 /> },
                { path: '*', element: <Navigate to="/404" /> },
            ],
        },
        {
            path: '*',
            element: <Navigate to="/404" replace />,
        },
    ]);

    return routes;
}
