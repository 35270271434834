import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { Grid } from '@mui/material';

function ConnectionCard({ connection }) {
    return (
        <Grid item xs={12} md={6} lg={4}>
            <Card sx={{ paddingBottom: 3 }}>
                <CardHeader
                    avatar={
                        <Avatar
                            sx={{ width: 56, height: 56 }}
                            src={`${process.env.REACT_APP_API_URL}/storage/${connection.profile_picture}`}
                        />
                    }
                    title={`${connection.fname} ${connection.lname}`}
                    subheader={connection.company}
                />
            </Card>
        </Grid>
    );
}

export default function ViewConnections({ connections, fetchUser }) {
    return (
        <>
            <Grid container spacing={3} sx={{ padding: 3 }}>
                {connections.map(
                    (conn, index) => conn.pivot.confirmed == 1 && <ConnectionCard connection={conn} key={index} />
                )}
            </Grid>
        </>
    );
}
