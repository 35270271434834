import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import api from 'services/api';
import { enqueueSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';

export default function ForgotPassword({ open, setOpen }) {

    const [email, setEmail] = useState('');

    const [loading, setLoading] = useState(false);

    function handleClose() {
        setOpen(false);
    }

    async function handleReset() {
        setLoading(true);

        const form = { email };

        try {
            const response = await api.post('/api/auth/password/email', form);
            enqueueSnackbar(response.data.message, { variant: 'success' });
        } catch (error) {
            console.log(error);
            enqueueSnackbar('Something went wrong. Please try again later.', { variant: 'error' });
        }

        setLoading(false);
        setOpen(false);
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogTitle>Password Reset</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        We will send a temporary password to your email.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Email Address"
                        type="email"
                        fullWidth
                        variant="outlined"
                        sx={{ marginTop: 2 }}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <LoadingButton loading={loading} onClick={handleReset} variant="contained">Reset</LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}
