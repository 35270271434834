// @mui
import PropTypes from 'prop-types';
import { Box, Stack, Link, Card, Button, Divider, Typography, CardHeader } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
// utils
import { fToNow } from '../../../utils/formatTime';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';

// ----------------------------------------------------------------------

ProfileProject.propTypes = {
    title: PropTypes.string,
    subheader: PropTypes.string,
    list: PropTypes.array.isRequired,
};

export default function ProfileProject({ title, subheader, list, showAdd, ...other }) {
    const navigate = useNavigate();

    function handleAdd() {
        navigate('/dashboard/project/add');
    }

    return (
        <Card {...other}>
            <CardHeader title={title} subheader={subheader} />

            <Scrollbar>
                <Stack spacing={1} sx={{ p: 2 }}>
                    {list.map((news) => (
                        <NewsItem key={news.id} news={news} />
                    ))}
                </Stack>
            </Scrollbar>
            {showAdd &&
                <>
                    <Divider />

                    <Box sx={{ p: 2, textAlign: 'right' }}>
                        <Button onClick={handleAdd} size="small" color="inherit" startIcon={<Iconify icon={'mdi:add'} />}>
                            Add Project
                        </Button>
                        {/* <Button size="small" color="inherit" endIcon={<Iconify icon={'eva:arrow-ios-forward-fill'} />}>
                View all
                </Button> */}
                    </Box>
                </>
            }
        </Card>
    );
}

// ----------------------------------------------------------------------

NewsItem.propTypes = {
    news: PropTypes.shape({
        description: PropTypes.string,
        image: PropTypes.string,
        postedAt: PropTypes.instanceOf(Date),
        title: PropTypes.string,
    }),
};

function NewsItem({ news }) {
    const { image, title, description, postedAt, start, end } = news;

    return (
        <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            sx={{
                p: 1,
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: 'background.neutral',
                },
                borderRadius: 2
            }}
        >
            <Box
                component="img"
                alt={title}
                src={image}
                sx={{ width: 48, height: 48, borderRadius: 1.5, flexShrink: 0 }}
            />

            <Box sx={{ minWidth: 240, flexGrow: 1 }}>
                <Link color="inherit" variant="subtitle2" underline="hover" noWrap sx={{ textTransform: 'capitalize' }}>
                    {title}
                </Link>

                <Typography variant="caption" sx={{ color: 'text.secondary', display: 'block' }} noWrap>
                    {dayjs(start).format('LL')} - {dayjs(end).format('LL')}
                </Typography>

                <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                    {description}
                </Typography>
            </Box>

            <Typography variant="caption" sx={{ flexShrink: 0, color: 'text.secondary' }}>
                {fToNow(postedAt)}
            </Typography>
        </Stack>
    );
}
