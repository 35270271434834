import { ArrowBackIos, ArrowBackIosNew } from '@mui/icons-material';
import { Container, IconButton, Stack, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import BlogPostForm from 'sections/@dashboard/blog/BlogPostForm';

export default function PostAdd() {
    const navigate = useNavigate();

    function handleBack() {
        navigate(-1);
    }

    return (
        <>
            <Helmet>
                <title>Go Virtual Members: Add Post</title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="flex-start" mb={5}>
                    <IconButton color="primary" onClick={handleBack}>
                        <ArrowBackIosNew />
                    </IconButton>
                    <Typography variant="h4">
                        Add Post
                    </Typography>
                </Stack>
                <BlogPostForm />
            </Container>
        </>
    );
}
