import { ArrowBackIosNew } from '@mui/icons-material';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Container,
    Grid,
    IconButton,
    InputLabel,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import FileUpload from 'react-mui-fileuploader';
import { useNavigate } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LoadingButton } from '@mui/lab';
import Iconify from 'components/iconify/Iconify';
import dayjs from 'dayjs';
import api from 'services/api';
import { useSnackbar } from 'notistack';

const delay = ms => new Promise(r => setTimeout(r, ms));

export default function ProjectAdd() {
    const navigate = useNavigate();

    const [files, setFiles] = useState([]);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    function handleBack() {
        navigate(-1);
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setLoading(true);

        // const form = Object.fromEntries(new FormData(event.target).entries());

        const form = new FormData(event.target);

        form.append('image', files[0]);

        form.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));

        form.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));

        console.log(...form);

        try {
            const response = await api.upload('/api/projects', form);

            console.log(response.data);

            enqueueSnackbar(response.data.message, { variant: 'success' });

            await delay(500);

            navigate(-1);

        } catch (error) {
            console.log(error);

            if (error.response.data.message == undefined) {
                if (error.response.data.image) {
                    error.response.data.image.forEach(errorMsg => {
                        enqueueSnackbar(errorMsg, { variant: 'error' });
                    });
                }
                if (error.response.data.start_date) {
                    enqueueSnackbar(error.response.data.start_date[0], { variant: 'error' });
                }
                if (error.response.data.end_date) {
                    enqueueSnackbar(error.response.data.end_date[0], { variant: 'error' });
                }
            } else {
                enqueueSnackbar(error.response.data?.message ?? 'Invalid.', { variant: 'error' });
            }
        }

        setLoading(false);
    }

    function handleFilesChange(files) {
        // Update chosen files
        setFiles([...files]);
    }

    return (
        <>
            <Helmet>
                <title>Go Virtual Members: Add Project</title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="flex-start" mb={5}>
                    <IconButton color="primary" onClick={handleBack}>
                        <ArrowBackIosNew />
                    </IconButton>
                    <Typography variant="h4">Add Project</Typography>
                </Stack>
                <Card>
                    <form onSubmit={handleSubmit}>
                        <CardHeader title="Project Details" />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Stack>
                                        <InputLabel>Title</InputLabel>
                                        <TextField
                                            id="title_input"
                                            name="title"
                                            placeholder="Title"
                                            variant="outlined"
                                            required
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack>
                                        <InputLabel>Content</InputLabel>
                                        <TextField
                                            id="content_input"
                                            name="content"
                                            placeholder="Content"
                                            variant="outlined"
                                            required
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <FileUpload
                                        title="Cover Image"
                                        showPlaceholderImage={false}
                                        onFilesChange={handleFilesChange}
                                        onContextReady={(context) => {}}
                                        multiFile={false}
                                        maxUploadFiles={1}
                                        ContainerProps={{
                                            elevation: 0,
                                            variant: 'outlined',
                                            sx: { p: 1, borderStyle: 'dashed' },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack>
                                        <InputLabel>Start Date</InputLabel>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker value={startDate} onChange={(newValue) => setStartDate(newValue)}/>
                                        </LocalizationProvider>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack>
                                        <InputLabel>End Date</InputLabel>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker value={endDate} onChange={(newValue) => setEndDate(newValue)} />
                                        </LocalizationProvider>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions sx={{ p: 3 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <Button variant="outlined" onClick={handleBack}>
                                    Cancel
                                </Button>
                                <LoadingButton
                                    type="submit"
                                    loading={loading}
                                    disabled={loading}
                                    variant="contained"
                                    loadingPosition="start"
                                    startIcon={<Iconify icon="mdi:add" />}
                                >
                                    Add
                                </LoadingButton>
                            </Box>
                        </CardActions>
                    </form>
                </Card>
            </Container>
        </>
    );
}
