import { useEffect, useRef, useState } from 'react';

import { filter } from 'lodash';

import { Helmet } from 'react-helmet-async';

import { TopBarContent, BottomBarContent, SidebarContent, ChatContent } from 'sections/@dashboard/inbox';

import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';

import Scrollbar from 'components/inbox-scrollbar/Scrollbar';
// import Scrollbar from 'components/scrollbar/Scrollbar';

import { Box, styled, Divider, Drawer, IconButton, useTheme } from '@mui/material';
import api from 'services/api';
import { useSelector } from 'react-redux';
import useInterval from 'hooks/useInterval';
import { useParams } from 'react-router-dom';

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const RootWrapper = styled(Box)(({ theme }) => ({
    // height: `calc(100vh - ${HEADER_MOBILE}px)`,
    height: '100vh',
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
        // height: `calc(100vh - ${HEADER_DESKTOP}px)`,
        height: '100vh',
    },
}));

const Sidebar = styled(Box)(
    ({ theme }) => `
        width: 300px;
        background: ${theme.palette.background};
        border-right: ${theme.palette.divider} solid 1px;
`
);

const ChatWindow = styled(Box)(
    () => `
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
`
);

const ChatTopBar = styled(Box)(
    ({ theme }) => `
        background: ${theme.palette.background.default};
        border-bottom: ${theme.palette.divider} solid 1px;
        padding: ${theme.spacing(2)};
        align-items: center;
`
);

const IconButtonToggle = styled(IconButton)(
    ({ theme }) => `
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  background: ${theme.palette.background};
`
);

const DrawerWrapperMobile = styled(Drawer)(
    () => `
    width: 340px;
    flex-shrink: 0;

  & > .MuiPaper-root {
        width: 340px;
        z-index: 3;
  }
`
);

function InboxPage() {
    const theme = useTheme();

    const [mobileOpen, setMobileOpen] = useState(false);

    const { currentUser } = useSelector((state) => state.currentUser);

    const [threads, setThreads] = useState([]);

    const [search, setSearch] = useState('');

    const filteredThreads = applyFilter(threads, search);

    const [threadMessages, setThreadMessages] = useState();

    const chatScroll = useRef();

    const { id } = useParams();

    const [currentThread, setCurrentThread] = useState(id != 0 && { thread_id: id });

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    useEffect(() => {
        fetchThreads();
    }, []);

    async function fetchThreads() {
        try {
            const response = await api.get('/api/messages/user');
            setThreads(response.data);
            console.log('user_threads', response.data);
            // setCurrentThread(response.data[0]);
        } catch (error) {
            console.log(error);
        }
    }

    function fullnameFilterCheck(_thread, query) {
        const fullName = `${_thread.user.fname} ${_thread.user.lname}`;
        const checker = fullName.toLowerCase().indexOf(query.toLowerCase()) !== -1;

        return checker;
    }

    function applyFilter(array, query) {
        if (query) {
            return filter(array, (_thread) => fullnameFilterCheck(_thread, query));
        }
        return array;
    }

    useEffect(() => {
        if (currentThread) {
            fetchMessages();
        }
    }, [currentThread]);

    async function fetchMessages() {
        try {
            const response = await api.get(`/api/messages/show/${currentThread.thread_id}`);
            console.log('selected_thread', response.data);

            if (threadMessages) {
                const threadLastIndex = threadMessages.messages.length - 1;
                const responseLastIndex = response.data.messages.length - 1;
                if (response.data.messages[responseLastIndex].created_at == threadMessages.messages[threadLastIndex].created_at) {
                    return;
                }
            }
            setThreadMessages(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (threadMessages) {
            chatScroll.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [threadMessages]);

    const [count, setCount] = useState(0);

    useInterval(() => {
        // Your custom logic here
        setCount(count + 1);

        fetchMessages();
    }, 10000);

    return (
        <>
            <Helmet>
                <title>Go Virtual Members - Inbox</title>
            </Helmet>
            <RootWrapper className="Mui-FixedWrapper">
                <DrawerWrapperMobile
                    sx={{
                        display: { lg: 'none', xs: 'inline-block' },
                    }}
                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                >
                    <Scrollbar>
                        <SidebarContent threads={filteredThreads} currentThread={currentThread} setCurrentThread={setCurrentThread} search={search} setSearch={setSearch} />
                    </Scrollbar>
                </DrawerWrapperMobile>
                <Sidebar
                    sx={{
                        display: { xs: 'none', lg: 'inline-block' },
                    }}
                >
                    <Scrollbar>
                        <SidebarContent threads={filteredThreads} currentThread={currentThread} setCurrentThread={setCurrentThread} search={search} setSearch={setSearch} />
                    </Scrollbar>
                </Sidebar>
                <ChatWindow>
                    <ChatTopBar
                        sx={{
                            display: { xs: 'flex', lg: 'inline-block' },
                        }}
                    >
                        <IconButtonToggle
                            sx={{
                                display: { lg: 'none', xs: 'flex' },
                                mr: 2,
                            }}
                            color="primary"
                            onClick={handleDrawerToggle}
                            size="small"
                        >
                            <MenuTwoToneIcon />
                        </IconButtonToggle>
                        {threadMessages && <TopBarContent recipient={threadMessages.users[0]} />}
                    </ChatTopBar>
                    <Box flex={1}>
                        <Scrollbar>
                            <ChatContent
                                threadMessages={threadMessages}
                                currentUser={currentUser}
                                chatScroll={chatScroll}
                            />
                        </Scrollbar>
                    </Box>
                    <Divider />
                    <BottomBarContent currentThread={currentThread} fetchMessages={fetchMessages} />
                </ChatWindow>
            </RootWrapper>
        </>
    );
}

export default InboxPage;
