import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// @mui
import { Grid } from '@mui/material';
import ShopProductCard from './ProductCard';

// ----------------------------------------------------------------------

ProductList.propTypes = {
    products: PropTypes.array.isRequired,
};

export default function ProductList({ products, ...other }) {
    const navigate = useNavigate();

    function handleViewProfile(product) {
        if (product.isSupplier == 1) {
            navigate(`/dashboard/suppliers/${product.id}`);
        } else {
            navigate(`/dashboard/members/${product.id}`);
        }
    }

    return (
        <Grid container spacing={3} {...other}>
            {products.map((product) => (
                <Grid key={product.id} item xs={12} sm={6} md={4}>
                    <ShopProductCard product={product} handleView={() => handleViewProfile(product)} />
                </Grid>
            ))}
        </Grid>
    );
}
