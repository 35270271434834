import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { Box, Container, Grid, Stack, Typography, Button } from '@mui/material';

import api from 'services/api';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// mock
// import POSTS from '../_mock/blog';
// components
import { BlogPostCard, BlogPostsSearch, BlogPostsSort } from '../sections/@dashboard/blog';
import { EmptyCard } from '../sections/@dashboard/members';

import Iconify from '../components/iconify';

// ----------------------------------------------------------------------

const SORT_OPTIONS = [
    { value: 'desc', label: 'Latest' },
    { value: 'asc', label: 'Oldest' },
    // { value: 'popular', label: 'Popular' },
];

// ----------------------------------------------------------------------

export default function HomePage() {
    const navigate = useNavigate();

    const { currentUser } = useSelector((state) => state.currentUser);

    const [POSTS, setPosts] = useState([]);

    const [sort, setSort] = useState('desc');

    useEffect(() => {
        fetchPosts();
    }, [sort]);

    async function fetchPosts() {
        try {
            const response = await api.get(`/api/posts/filter/${sort}`);

            console.log(response.data);

            const _posts = response.data.posts.map((post, index) => ({
                id: post.id,
                cover: `${process.env.REACT_APP_API_URL}/storage/${post.image}`,
                title: post.title,
                createdAt: post.created_at,
                view: 0,
                comment: 0,
                share: 0,
                favorite: 0,
                author: {
                    name: '',
                    avatarUrl: null,
                },
                slug: post.id
            }));

            setPosts(_posts);
        } catch (error) {
            console.log(error);
            if (error.code == 'ERR_NETWORK') {
                console.log('Could not fetch data...');
                navigate('/404');
            }
            if (error.response.status == 401) {
                localStorage.clear();
                navigate('/login');
            }
        }
    }

    function handleAddPost() {
        navigate('/dashboard/post/add');
    }

    function handleSort(event) {
        setSort(event.target.value);
    }

    return (
        <>
            <Helmet>
                <title> Go Virtual Portal </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Home
                    </Typography>
                    {currentUser.role == 'admin' && (
                        <Button
                            onClick={handleAddPost}
                            variant="contained"
                            startIcon={<Iconify icon="eva:plus-fill" />}
                        >
                            New Post
                        </Button>
                    )}
                </Stack>

                <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
                    <BlogPostsSearch posts={POSTS} />
                    <BlogPostsSort options={SORT_OPTIONS} sort={sort} onSort={handleSort} />
                </Stack>

                {POSTS?.length > 0 ?
                    <Grid container spacing={3}>
                        {POSTS.map((post, index) => (
                            <BlogPostCard key={post.id} post={post} index={index} />
                        ))}
                    </Grid>
                    :
                    <EmptyCard />
                }
            </Container>
        </>
    );
}
