// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
    {
        title: 'home',
        path: '/dashboard/home',
        icon: icon('ic_home'),
    },
    {
        title: 'manage members',
        path: '/dashboard/manage-members',
        icon: icon('ic_add'),
    },
    {
        title: 'members',
        path: '/dashboard/members',
        icon: icon('ic_user'),
    },
    {
        title: 'suppliers',
        path: '/dashboard/suppliers',
        icon: icon('ic_supplier'),
    },
    {
        title: 'profile',
        path: '/dashboard/profile',
        icon: icon('ic_profile')
    },
    {
        title: 'inbox',
        path: '/dashboard/inbox/0',
        icon: icon('ic_message')
    }
];

export default navConfig;
