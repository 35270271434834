// @mui
import { Grid, Box, Typography } from '@mui/material';

export default function EmptyCard() {

    return (
        <Grid container spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: '50vh' }}
        >
            <Box
                component="img"
                src="/assets/empty.png"
                sx={{ width: 300, height: 300 }}
            />
            <Typography variant="h5" gutterBottom>
                Nothing here yet.
            </Typography>
        </Grid>
    );
}
