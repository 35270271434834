import PropTypes from 'prop-types';
// @mui
import { Avatar, Box, Card, CardContent, Grid, Link, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// utils
import { useNavigate } from 'react-router-dom';
import { fShortenNumber } from '../../../utils/formatNumber';
import { fDate } from '../../../utils/formatTime';
//
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledCardMedia = styled('div')({
    position: 'relative',
    paddingTop: 'calc(100% * 3 / 4)',
});

const StyledTitle = styled(Link)({
    height: 44,
    overflow: 'hidden',
    WebkitLineClamp: 2,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
});

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    zIndex: 9,
    width: 32,
    height: 32,
    position: 'absolute',
    left: theme.spacing(3),
    bottom: theme.spacing(-2),
}));

const StyledInfo = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    color: theme.palette.text.disabled,
}));

const StyledCover = styled('img')({
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
});

// ----------------------------------------------------------------------

BlogPostCard.propTypes = {
    post: PropTypes.object.isRequired,
    index: PropTypes.number,
};

export default function BlogPostCard({ post, index }) {
    const { cover, title, view, comment, share, author, createdAt, slug } = post;
    const latestPostLarge = index === 0;
    const latestPost = index === 1 || index === 2;
    const navigate = useNavigate();

    const POST_INFO = [
        { number: comment, icon: 'eva:message-circle-fill' },
        { number: view, icon: 'eva:eye-fill' },
        { number: share, icon: 'eva:share-fill' },
    ];

    function handleClick() {
        navigate(`/dashboard/post/${slug}`);
    }

    return (
        <Grid item xs={12} sm={latestPostLarge ? 12 : 6} md={latestPostLarge ? 6 : 3}>
            <Card
                sx={{
                    position: 'relative',
                    cursor: 'pointer',
                    '&:hover': {
                        boxShadow:
                            'rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;',
                        transform: 'translateY(-3px)',
                        transitionTimingFunction: 'ease',
                        transition: 'all 0.5s',
                    },
                }}
                onClick={handleClick}
            >
                <StyledCardMedia
                    sx={{
                        ...((latestPostLarge || latestPost) && {
                            pt: 'calc(100% * 4 / 3)',
                            '&:after': {
                                top: 0,
                                content: "''",
                                width: '100%',
                                height: '100%',
                                position: 'absolute',
                                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.3),
                            },
                        }),
                        ...(latestPostLarge && {
                            pt: {
                                xs: 'calc(100% * 4 / 3)',
                                sm: 'calc(100% * 3 / 4.66)',
                            },
                        }),
                    }}
                >
                    {/* <SvgColor
            color="paper"
            src="/assets/icons/shape-avatar.svg"
            sx={{
              width: 80,
              height: 36,
              zIndex: 9,
              bottom: -15,
              position: 'absolute',
              color: 'background.paper',
              ...((latestPostLarge || latestPost) && { display: 'none' }),
            }}
          /> */}
                    {/* <StyledAvatar
            alt={author.name}
            src={author.avatarUrl}
            sx={{
              ...((latestPostLarge || latestPost) && {
                zIndex: 9,
                top: 24,
                left: 24,
                width: 40,
                height: 40,
              }),
            }}
          /> */}

                    <StyledCover alt={title} src={cover} />
                </StyledCardMedia>

                <CardContent
                    sx={{
                        pt: 4,
                        ...((latestPostLarge || latestPost) && {
                            bottom: 0,
                            width: '100%',
                            position: 'absolute',
                        }),
                    }}
                >
                    <Typography
                        gutterBottom
                        variant="caption"
                        sx={{
                            color: 'text.disabled',
                            display: 'block',
                            ...((latestPostLarge || latestPost) && {
                                color: 'common.white',
                            }),
                        }}
                    >
                        {fDate(createdAt)}
                    </Typography>

                    <StyledTitle
                        color="inherit"
                        variant="subtitle2"
                        underline="hover"
                        sx={{
                            ...(latestPostLarge && { typography: 'h5', height: 60 }),
                            ...((latestPostLarge || latestPost) && {
                                color: 'common.white',
                            }),
                            textTransform: 'capitalize'
                        }}
                    >
                        {title}
                    </StyledTitle>

                    {/* <StyledInfo>
                        {POST_INFO.map((info, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    ml: index === 0 ? 0 : 1.5,
                                    ...((latestPostLarge || latestPost) && {
                                        color: 'common.white',
                                    }),
                                }}
                            >
                                <Iconify icon={info.icon} sx={{ width: 16, height: 16, mr: 0.5 }} />
                                <Typography variant="caption">{fShortenNumber(info.number)}</Typography>
                            </Box>
                        ))}
                    </StyledInfo> */}
                </CardContent>
            </Card>
        </Grid>
    );
}
