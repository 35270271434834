import { Box, Avatar, Typography, Card, styled, Divider } from '@mui/material';

import { formatDistance, format, subDays, subHours, subMinutes } from 'date-fns';
import ScheduleTwoToneIcon from '@mui/icons-material/ScheduleTwoTone';
import { useRef } from 'react';

const DividerWrapper = styled(Divider)(
    ({ theme }) => `
      .MuiDivider-wrapper {
        border-radius: ${theme.general.borderRadiusSm};
        text-transform: none;
        background: ${theme.palette.background.default};
        font-size: ${theme.typography.pxToRem(13)};
        color: ${theme.palette.grey[900]};
      }
`
);

const CardWrapperPrimary = styled(Card)(
    ({ theme }) => `
      background: ${theme.palette.primary.main};
      color: ${theme.palette.primary.contrastText};
      padding: ${theme.spacing(2)};
      border-radius: ${theme.general.borderRadiusXl};
      border-top-right-radius: ${theme.general.borderRadius};
      max-width: 380px;
      display: inline-flex;
`
);

const CardWrapperSecondary = styled(Card)(
    ({ theme }) => `
      background: ${theme.palette.background.paper};
      color: ${theme.palette.grey[900]};
      padding: ${theme.spacing(2)};
      border-radius: ${theme.general.borderRadiusXl};
      border-top-left-radius: ${theme.general.borderRadius};
      max-width: 380px;
      display: inline-flex;
`
);

function Message({ participant, message, currentUser }) {
    const isCurrentUser = currentUser.id == message.user_id;
    let profileImg = `${process.env.REACT_APP_API_URL}/storage/${message.profile_picture}`;

    if (isCurrentUser) {
        return (
            <Box display="flex" alignItems="flex-start" justifyContent="flex-end" py={3}>
                <Box display="flex" alignItems="flex-end" flexDirection="column" justifyContent="flex-end" mr={2}>
                    <CardWrapperPrimary>{message.body}</CardWrapperPrimary>
                    <Typography
                        variant="subtitle2"
                        sx={{
                            pt: 1,
                            display: 'flex',
                            alignItems: 'center',
                            color: 'text.secondary',
                        }}
                    >
                        <ScheduleTwoToneIcon
                            sx={{
                                mr: 0.5,
                            }}
                            fontSize="small"
                        />
                        {formatDistance(new Date(message.created_at), new Date(), {
                            addSuffix: true,
                        })}
                    </Typography>
                </Box>
                <Avatar
                    variant="rounded"
                    sx={{
                        width: 50,
                        height: 50,
                    }}
                    alt={'alt'}
                    src={profileImg}
                />
            </Box>
        );
    }

    return (
        <Box display="flex" alignItems="flex-start" justifyContent="flex-start" py={3}>
            <Avatar
                variant="rounded"
                sx={{
                    width: 50,
                    height: 50,
                }}
                alt={'alt'}
                src={profileImg}
            />
            <Box display="flex" alignItems="flex-start" flexDirection="column" justifyContent="flex-start" ml={2}>
                <CardWrapperSecondary>{message.body}</CardWrapperSecondary>
                <Typography
                    variant="subtitle2"
                    sx={{
                        pt: 1,
                        display: 'flex',
                        alignItems: 'center',
                        color: 'text.secondary',
                    }}
                >
                    <ScheduleTwoToneIcon
                        sx={{
                            mr: 0.5,
                        }}
                        fontSize="small"
                    />
                    {formatDistance(new Date(message.created_at), new Date(), {
                        addSuffix: true,
                    })}
                </Typography>
            </Box>
        </Box>
    );
}

function ChatContent({ threadMessages, currentUser, chatScroll }) {
    const user = {
        name: 'Juan Dela Cruz',
        avatar: '/assets/images/avatars/avatar_default.jpg',
    };

    if (threadMessages == undefined) {
        return <></>;
    }

    return (
        <Box p={3}>
            {/* <DividerWrapper>{format(subDays(new Date(), 3), 'MMMM dd yyyy')}</DividerWrapper> */}

            {threadMessages.messages.map((message, index) => (
                <Message
                    key={index}
                    participant={threadMessages.users[0]}
                    message={message}
                    currentUser={currentUser}
                />
            ))}

            <div ref={chatScroll} />

            {/* <DividerWrapper>{format(subDays(new Date(), 5), 'MMMM dd yyyy')}</DividerWrapper> */}
        </Box>
    );
}

export default ChatContent;
