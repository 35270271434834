import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import api from 'services/api';
import { enqueueSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';

export default function MessageModal({ open, setOpen, recipient }) {

    const [content, setContent] = useState('');

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    function handleClose() {
        setOpen(false);
    }

    async function handleSend() {
        setLoading(true);

        const form = {
            subject: 'direct_message',
            message: content,
            recipients: recipient.id
        };

        try {
            const response = await api.post('/api/messages/new', form);
            console.log(response.data);
            enqueueSnackbar('Message sent! You can now view your conversation in your inbox.', { variant: 'success' });
            navigate(`/dashboard/inbox/${response.data.sent_message.thread.id}`);
        } catch (error) {
            console.log(error);
            enqueueSnackbar('Something went wrong. Please try again later.', { variant: 'error' });
        }

        setLoading(false);
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose} fullWidth size="md">
                <DialogTitle>Send Message</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Say hello to {recipient.fname}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="message"
                        label="Message"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <LoadingButton loading={loading} onClick={handleSend}>Send</LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}
