import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// @mui
import { Box, Card, Link, Typography, Stack, Avatar, Button, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';
// utils
import { fCurrency } from '../../../utils/formatNumber';
// components
import Label from '../../../components/label';
import { ColorPreview } from '../../../components/color-utils';

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
});

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    width: 32,
    height: 32,
    // left: theme.spacing(3),
    // bottom: theme.spacing(-2),
}));

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `7B${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
            p: 2,
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
    product: PropTypes.object,
};

export default function ShopProductCard({ product, handleView }) {
    const { name, avatar, cover, status, shortDesc, connectionStatus } = product;

    return (
        <Card
            onClick={handleView}
            sx={{
                cursor: 'pointer',
                '&:hover': {
                    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;',
                    transform: 'translateY(-3px)',
                    // transitionTimingFunction: 'ease',
                    // transition: 'all 0.5s',
                },
            }}
        >
            <Box sx={{ pt: '70%', position: 'relative' }}>
                {status && (
                    <Label
                        variant="filled"
                        color={(status === 'sale' && 'error') || 'info'}
                        sx={{
                            zIndex: 9,
                            top: 16,
                            right: 16,
                            position: 'absolute',
                            textTransform: 'uppercase',
                        }}
                    >
                        {status}
                    </Label>
                )}
                <StyledProductImg alt={name} src={cover} />
            </Box>

            <Stack spacing={2} sx={{ p: 3 }}>
                <Stack>
                    <Typography variant="subtitle2" noWrap>
                        {name}
                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom>
                        {shortDesc}
                    </Typography>
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    {avatar ? (
                        <StyledAvatar
                            alt={name}
                            src={avatar}
                            sx={{
                                width: 60,
                                height: 60,
                            }}
                        />
                    ) : (
                        <Avatar {...stringAvatar(name)} />
                    )}
                    {/* <img src={avatar} alt="" /> */}
                    {/* <Typography variant="subtitle1">
                        <Typography
                            component="span"
                            variant="body1"
                            sx={{
                                color: 'text.disabled',
                                textDecoration: 'line-through',
                            }}
                        >
                            {priceSale && fCurrency(priceSale)}
                        </Typography>
                        &nbsp;
                        {fCurrency(price)}
                    </Typography> */}
                    {/* <Typography variant="subtitle1">{memberType}</Typography> */}
                    {/* <Button onClick={handleConnect} variant="outlined" startIcon={<AddIcon />} >Connect</Button> */}
                    {connectionStatus == 'connected' && <Chip label={"Connected"} variant="outlined" color="primary" />}
                    {connectionStatus == 'pending' && <Chip label={"Pending Approval"} variant="outlined" color="warning" />}
                    {/* <Chip label="Not Connected" variant="outlined" /> */}
                </Stack>
            </Stack>
        </Card>
    );
}
