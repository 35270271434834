import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URL;

const getHeaders = () => {
    const token = localStorage.getItem('token');

    return {
        Authorization: `Bearer ${token}`,
    };
};

async function post(url, body) {
    console.log(baseUrl);
    const response = await axios.post(baseUrl + url, body, { headers: getHeaders() });

    return response;
}

async function get(url, params) {
    const response = await axios.get(baseUrl + url, {
        params,
        headers: getHeaders(),
    });

    return response;
}

async function put(url, body) {
    const response = await axios.put(baseUrl + url, body, { headers: getHeaders() });

    return response;
}

async function destroy(url) {
    const response = await axios.delete(baseUrl + url, { headers: getHeaders() });
    return response;
}

async function upload(url, form) {
    const response = await axios.postForm(baseUrl + url, form, { headers: getHeaders() });
    return response;
}

export default { post, get, put, destroy, upload };
