import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import Label from 'components/label';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import api from 'services/api';
import defaultUser from 'schema/defaultUser';
import Iconify from 'components/iconify/Iconify';
import { useSnackbar } from 'notistack';

export default function UserFormUpdate({ id }) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(defaultUser);

    const [showPassword, setShowPassword] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        fetchUser();
    }, []);

    async function fetchUser() {
        try {
            const response = await api.get(`/api/users/${id}`);
            setUser(response.data.user);
            console.log('edit user', response.data.user);
        } catch (error) {
            console.log(error);
        }
    }

    function handleCancel() {
        navigate(-1);
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setLoading(true);

        let formData = Object.fromEntries(new FormData(event.target).entries());
        console.log(formData);

        // let postBody = {
        //     fname: formData.first_name,
        //     lname: formData.last_name,
        //     email: formData.email,
        //     password: formData.password,
        //     mobile_no: formData.mobile,
        //     company: formData.company,
        //     member_type: formData.member_type,
        //     role: 'member'
        // }
        
        // Check if string not empty

        let body = {};

        if (formData.first_name != '') {
            body.fname = formData.first_name;
        }

        if (formData.last_name != '') {
            body.lname = formData.last_name;
        }

        if (formData.email != '') {
            body.email = formData.email;
        }

        if (formData.password != '') {
            body.password = formData.password;
        }

        if (formData.mobile != '') {
            body.mobile_no = formData.mobile;
        }

        if (formData.company != '') {
            body.company = formData.company;
        }

        // add state to member_type

        if (Object.keys(body).length == 0) {
            console.log('nothing to update...');
            enqueueSnackbar('Nothing to update.', { variant: 'warning' });

            setLoading(false);
            return;
        }

        try {
            const response = await api.put(`/api/users/${id}`, body);
            console.log(response.data);
            enqueueSnackbar(response.data.message, { variant: 'success' });
            navigate(-1);
        } catch (error) {
            console.log(error);
            enqueueSnackbar(error.response.data?.message ?? 'Invalid.', { variant: 'error' });
        }

        setLoading(false);
    }

    return (
        <Card>
            <form onSubmit={handleSubmit}>
                <CardHeader title="Update Member" />
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Stack>
                                <InputLabel>First Name</InputLabel>
                                <TextField
                                    id="first_name_input"
                                    name="first_name"
                                    placeholder={user.fname}
                                    variant="outlined"
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack>
                                <InputLabel>Last Name</InputLabel>
                                <TextField
                                    id="last_name_input"
                                    name="last_name"
                                    placeholder={user.lname}
                                    variant="outlined"
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack>
                                <InputLabel>Email</InputLabel>
                                <TextField
                                    id="email_input"
                                    name="email"
                                    placeholder={user.email}
                                    variant="outlined"
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack>
                                <InputLabel>Mobile Number</InputLabel>
                                <TextField
                                    id="mobile_input"
                                    name="mobile"
                                    placeholder={user.mobile_no}
                                    variant="outlined"
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack>
                                <InputLabel>Company Name</InputLabel>
                                <TextField
                                    id="company_input"
                                    name="company"
                                    placeholder={user.company}
                                    variant="outlined"
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <Stack>
                                    <Typography color="text.secondary">Member Type (not working yet)</Typography>
                                    <Select
                                        id="demo-simple-select"
                                        name="member_type"
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        displayEmpty
                                        defaultValue="regular"
                                    >
                                        <MenuItem value="regular">Regular Member</MenuItem>
                                        <MenuItem value="affiliate">Affiliate Member</MenuItem>
                                    </Select>
                                </Stack>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack>
                                <InputLabel>Update Password</InputLabel>
                                <TextField
                                    id="password_input"
                                    name="password"
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Temporary Password"
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions sx={{ p: 3 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Button variant="outlined" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <LoadingButton
                            type="submit"
                            loading={loading}
                            disabled={loading}
                            variant="contained"
                            loadingPosition="start"
                            startIcon={<EditIcon />}
                        >
                            Update
                        </LoadingButton>
                    </Box>
                </CardActions>
            </form>
        </Card>
    );
}
