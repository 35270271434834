import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { LoadingButton } from '@mui/lab';
import { Alert, Checkbox, IconButton, InputAdornment, Link, Stack, TextField, Typography } from '@mui/material';

import { useFormik } from 'formik';
import * as yup from 'yup';

import api from 'services/api';
import { setCurrentUser } from 'store/current-user';
import { useDispatch } from 'react-redux';

import Iconify from '../../../components/iconify';
import ForgotPassword from './ForgotPassword';

// ----------------------------------------------------------------------

const validationSchema = yup.object({
    email: yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
    password: yup
        .string('Enter your password')
        .min(6, 'Password should be of minimum 6 characters length')
        .required('Password is required'),
});

export default function LoginForm() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [showPassword, setShowPassword] = useState(false);
    const [loginError, setLoginError] = useState(null);
    const [loading, setLoading] = useState(false);

    const [openForgotPass, setOpenForgotPass] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema,
        onSubmit,
    });

    async function onSubmit(values) {
        // console.log(values);
        setLoading(true);

        try {
            const response = await api.post('/api/auth/login', values);
            console.log(response.data);

            localStorage.setItem('token', response.data.access_token);
            dispatch(setCurrentUser(response.data.user));

            navigate('/dashboard/home');
        } catch (error) {
            console.log(error);
            setLoginError(error.response.data.error);
            setLoading(false);
        }
    }

    function handleForgotPass() {
        setOpenForgotPass(true);
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <ForgotPassword open={openForgotPass} setOpen={setOpenForgotPass} />

            <Stack spacing={3}>
                {loginError && <Alert severity="error">{loginError}</Alert>}

                <TextField
                    name="email"
                    label="Email address"
                    id="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                />

                <TextField
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    inputProps={{ name: 'password' }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                />
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                {/* <Checkbox name="remember" label="Remember me" /> */}
                <Link component="button" type="button" variant="subtitle2" underline="hover" onClick={handleForgotPass}>
                    Forgot password?
                </Link>
            </Stack>

            <LoadingButton loading={loading} fullWidth size="large" type="submit" variant="contained">
                Login
            </LoadingButton>
        </form>
    );
}
