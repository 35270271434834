import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
    Container,
    IconButton
} from '@mui/material';
import Label from 'components/label';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';
import api from 'services/api';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew';
import Iconify from 'components/iconify/Iconify';
import { useSnackbar } from 'notistack';

const delay = ms => new Promise(r => setTimeout(r, ms));

export default function ProfilePageEdit() {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const { currentUser } = useSelector((state) => state.currentUser);

    const { enqueueSnackbar } = useSnackbar();

    function handleBack() {
        navigate(-1);
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setLoading(true);

        const formData = Object.fromEntries(new FormData(event.target).entries());
        console.log(formData);

        try {
            const response = await api.post('/api/auth/update-user-profile', formData);
            console.log(response.data);
            enqueueSnackbar(response.data.message, { variant: 'success' });
            setLoading(false);
            await delay(500);
            navigate(-1);
        } catch (error) {
            console.log(error);
            enqueueSnackbar(error.response.data.message ?? 'Something went wrong. Please try again later.', { variant: 'error' });
            setLoading(false);
        }
    }

    return (
        <>
            <Helmet>
                <title>Go Virtual Members: Update Profile</title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="flex-start" mb={5}>
                    <IconButton color="primary" onClick={handleBack}>
                        <ArrowBackIosNew />
                    </IconButton>
                    <Typography variant="h4">Update Profile</Typography>
                </Stack>
                <Card>
                    <form onSubmit={handleSubmit}>
                        <CardHeader title="Update Profile" />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Stack>
                                        <InputLabel>First Name</InputLabel>
                                        <TextField
                                            id="first_name_input"
                                            name="fname"
                                            placeholder="First Name"
                                            variant="outlined"
                                            required
                                            defaultValue={currentUser.fname}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack>
                                        <InputLabel>Last Name</InputLabel>
                                        <TextField
                                            id="last_name_input"
                                            name="lname"
                                            placeholder="Last Name"
                                            variant="outlined"
                                            required
                                            defaultValue={currentUser.lname}
                                        />
                                    </Stack>
                                </Grid>
                                {/* <Grid item xs={12} md={6}>
                            <Stack>
                                <InputLabel>Email</InputLabel>
                                <TextField
                                    id="email_input"
                                    name="email"
                                    placeholder="Email"
                                    variant="outlined"
                                    required
                                />
                            </Stack>
                        </Grid> */}
                                <Grid item xs={12} md={6}>
                                    <Stack>
                                        <InputLabel>Mobile Number</InputLabel>
                                        <TextField
                                            id="mobile_input"
                                            name="mobile"
                                            placeholder="Mobile Number"
                                            variant="outlined"
                                            required
                                            defaultValue={currentUser.mobile_no}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack>
                                        <InputLabel>Company Name</InputLabel>
                                        <TextField
                                            id="company_input"
                                            name="company"
                                            placeholder="Company Name"
                                            variant="outlined"
                                            required
                                            defaultValue={currentUser.company}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack>
                                        <InputLabel>Department</InputLabel>
                                        <TextField
                                            id="department_input"
                                            name="department"
                                            placeholder="Department"
                                            variant="outlined"
                                            required
                                            defaultValue={currentUser.department}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack>
                                        <InputLabel>Education</InputLabel>
                                        <TextField
                                            id="education_input"
                                            name="education"
                                            placeholder="Education"
                                            variant="outlined"
                                            required
                                            defaultValue={currentUser.education}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack>
                                        <InputLabel>Work History</InputLabel>
                                        <TextField
                                            id="work_input"
                                            name="work_history"
                                            placeholder="Work History"
                                            variant="outlined"
                                            required
                                            defaultValue={currentUser.work_history}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack>
                                        <InputLabel>Languages</InputLabel>
                                        <TextField
                                            id="languages_input"
                                            name="languages"
                                            placeholder="Languages"
                                            variant="outlined"
                                            required
                                            defaultValue={currentUser.languages}
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions sx={{ p: 3 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <Button variant="outlined" onClick={handleBack}>
                                    Cancel
                                </Button>
                                <LoadingButton
                                    type="submit"
                                    loading={loading}
                                    disabled={loading}
                                    variant="contained"
                                    loadingPosition="start"
                                    startIcon={<Iconify icon="uil:pen" />}
                                >
                                    Update
                                </LoadingButton>
                            </Box>
                        </CardActions>
                    </form>
                </Card>
            </Container>
        </>
    );
}
