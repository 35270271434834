/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable jsx-a11y/label-has-for */
import { Avatar, Tooltip, IconButton, Box, Button, styled, InputBase, useTheme } from '@mui/material';
import AttachFileTwoToneIcon from '@mui/icons-material/AttachFileTwoTone';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import api from 'services/api';
import { enqueueSnackbar } from 'notistack';

const MessageInputWrapper = styled(InputBase)(
    ({ theme }) => `
    font-size: ${theme.typography.pxToRem(16)};
    padding: ${theme.spacing(1)};
    width: 100%;
`
);

const Input = styled('input')({
    display: 'none',
});

function BottomBarContent({ currentThread, fetchMessages }) {
    const theme = useTheme();

    const [content, setContent] = useState('');

    const [loading, setLoading] = useState(false);

    const user = {
        name: 'Juan Dela Cruz',
        avatar: '/assets/images/avatars/avatar_default.jpg',
    };

    async function handleSubmit(event) {
        event.preventDefault();

        setLoading(true);

        console.log(content);

        const formBody = {
            subject: '',
            message: content,
            recipients: currentThread.user_id
        };

        setContent('');

        try {
            const response = await api.post(`/api/messages/update/${currentThread.thread_id}`, formBody);

            enqueueSnackbar('Message sent!', { variant: 'success' });
            fetchMessages();
        } catch (error) {
            console.log(error);

            enqueueSnackbar('Something went wrong. Please try again later.', { variant: 'error' });
        }

        setLoading(false);
    }

    return (
        <Box
            sx={{
                background: theme.palette.background.default,
                display: 'flex',
                alignItems: 'center',
                p: 2,
            }}
            component="form"
            onSubmit={handleSubmit}
        >
            <Box flexGrow={1} display="flex" alignItems="center">
                {/* <Avatar
            sx={{ display: { xs: 'none', sm: 'flex' }, mr: 1 }}
            alt={'alt'}
            src={`${process.env.REACT_APP_API_URL}/storage/${currentUser.profile_picture}`}
          /> */}
                <MessageInputWrapper
                    autoFocus
                    placeholder="Write your message here..."
                    fullWidth
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                />
            </Box>
            <Box display="flex" gap={1}>
                {/* <Tooltip arrow placement="top" title="Choose an emoji">
            <IconButton
              sx={{ fontSize: theme.typography.pxToRem(16) }}
              color="primary"
            >
              😀
            </IconButton>
          </Tooltip> */}
                {/* <Input accept="image/*" id="messenger-upload-file" type="file" />
          <Tooltip arrow placement="top" title="Attach a file">
            <label htmlFor="messenger-upload-file">
              <IconButton sx={{ mx: 1 }} color="primary" component="span">
                <AttachFileTwoToneIcon fontSize="small" />
              </IconButton>
            </label>
          </Tooltip> */}
                <LoadingButton
                    loading={loading}
                    loadingPosition="start"
                    type="submit"
                    startIcon={<SendTwoToneIcon />}
                    variant="outlined"
                    size="large"
                >
                    Send
                </LoadingButton>
            </Box>
        </Box>
    );
}

export default BottomBarContent;
