import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { styled } from '@mui/material/styles';
import { Grid, Container, Stack, IconButton, Typography } from '@mui/material';
import { AppInfo, ProfileGallery, ProfileCover, ProfileProject, ProfileConnections, ViewConnections } from 'sections/@dashboard/profile';
import { AcademicCapIcon, BriefcaseIcon, HeartIcon, LanguageIcon, UserGroupIcon } from '@heroicons/react/24/solid';
import { useDispatch } from 'react-redux';
import api from 'services/api';
import { setCurrentUser } from 'store/current-user';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { AccountBox, ArrowBackIosNew, Diversity3, PersonAdd } from '@mui/icons-material';
import MuiTabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';

// ----------------------------------------------------------------------

const StyledTabs = styled((props) => (
    <MuiTabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        // maxWidth: 60,
        width: '100%',
        backgroundColor: '#1075ba',
        // backgroundColor: theme.typography.primary,
    },
    minHeight: 0,
});

const StyledTab = styled((props) => <MuiTab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(2),
    minHeight: 0,
    paddingBottom: 15,
    // color: 'rgba(255, 255, 255, 0.7)',
    color: 'rgba(0, 0, 0, 0.85)',
    '&.Mui-selected': {
        color: 'rgba(0, 0, 0, 0.85)',
        // color: theme.typography.primary,
    },
    '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
}));

function Profile({ user, fetchUser }) {
    const navigate = useNavigate();

    return (
        <>
            {user && (
                <>
                    <Grid item xs={12} md={6}>
                        <AppInfo
                            title="General Information"
                            list={[...Array(4)].map((_, index) => ({
                                id: faker.datatype.uuid(),
                                info: [
                                    user.education,
                                    // 'Real Estate',
                                    user.company,
                                    user.work_history,
                                    user.languages,
                                    // 'August 29, 1995',
                                ][index],
                                type: `order${index + 1}`,
                                title: [
                                    'Education',
                                    // 'Field',
                                    'Organization',
                                    'Work Experience',
                                    'Languages',
                                    // 'Birthday',
                                ][index],
                                icon: [
                                    <AcademicCapIcon />,
                                    <HeartIcon />,
                                    <UserGroupIcon />,
                                    <BriefcaseIcon />,
                                    <LanguageIcon />,
                                    // <CakeIcon />,
                                ][index],
                            }))}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <ProfileProject
                            title="All Projects"
                            list={user.projects.map((project, index) => ({
                                id: project.id,
                                title: project.title,
                                description: project.content,
                                image: `${process.env.REACT_APP_API_URL}/storage/${project.image}`,
                                start: project.start_date,
                                end: project.end_date,
                                postedAt: new Date(project.created_at),
                            }))}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <ProfileGallery
                            itemData={user.galleries.map((project, index) => ({
                                id: project.id,
                                title: project.title,
                                image: `${process.env.REACT_APP_API_URL}/storage/${project.image}`,
                            }))}
                            fetchUser={fetchUser}
                        />
                    </Grid>
                </>
            )}
        </>
    );
}

export default function MemberPageView() {
    const navigate = useNavigate();

    const [user, setUser] = useState();

    const { id } = useParams();

    const [tabIndex, setTabIndex] = useState(0);

    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    useEffect(() => {
        fetchUser();
    }, []);

    async function fetchUser() {
        try {
            const response = await api.get(`/api/users/${id}`);
            console.log(response.data);
            setUser(response.data.user);
        } catch (error) {
            console.log(error);
        }
    }

    function handleBack() {
        navigate(-1);
    }

    return (
        <>
            <Helmet>
                <title> Go Virtual Members: Member Profile </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="flex-start" mb={5}>
                    <IconButton color="primary" onClick={handleBack}>
                        <ArrowBackIosNew />
                    </IconButton>
                    <Typography variant="h4">Member Profile</Typography>
                </Stack>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <ProfileCover user={user} fetchUser={fetchUser} />
                    </Grid>

                    <Grid item xs={12}>
                        <StyledTabs value={tabIndex} onChange={handleChange} aria-label="styled tabs example">
                            <StyledTab icon={<AccountBox />} iconPosition="start" label="Profile" value={0} />
                            <StyledTab icon={<Diversity3 />} iconPosition="start" label="Connections" value={1} />
                        </StyledTabs>
                        <Grid container spacing={3} sx={{ paddingTop: 3 }}>
                            {tabIndex == 0 && <Profile user={user} fetchUser={fetchUser} />}
                            {tabIndex == 1 && (
                                <ViewConnections connections={user?.friends ?? []} />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
