import { useState } from 'react';
import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
import './quill.snow.css';

const modules = {
    toolbar: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        ['link'],
        ['clean'],
    ],
};

const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    // 'image',
];

export default function ReactQuillEditor({ content, setContent }) {
    // const [content, setContent] = useState();

    function handleChange(value) {
        setContent(value);
    }

    return (
        <div>
            <ReactQuill value={content} modules={modules} formats={formats} onChange={handleChange} />
        </div>
    );
}
