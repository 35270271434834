import { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    FormControlLabel,
    Switch,
    Tabs,
    Tab,
    TextField,
    IconButton,
    InputAdornment,
    Avatar,
    List,
    Button,
    Tooltip,
    Divider,
    AvatarGroup,
    ListItemButton,
    ListItemAvatar,
    ListItemText,
    lighten,
    styled,
} from '@mui/material';
import { formatDistance, subMinutes, subHours } from 'date-fns';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import Label from 'components/label/Label';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import AlarmTwoToneIcon from '@mui/icons-material/AlarmTwoTone';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import api from 'services/api';

const AvatarSuccess = styled(Avatar)(
    ({ theme }) => `
          background-color: ${theme.palette.success.light};
          color: ${theme.palette.success.main};
          width: ${theme.spacing(8)};
          height: ${theme.spacing(8)};
          margin-left: auto;
          margin-right: auto;
    `
);

const MeetingBox = styled(Box)(
    ({ theme }) => `
          background-color: ${lighten(theme.palette.grey[300], 0.5)};
          margin: ${theme.spacing(2)} 0;
          border-radius: ${theme.general.borderRadius};
          padding: ${theme.spacing(2)};
    `
);

const RootWrapper = styled(Box)(
    ({ theme }) => `
        padding: ${theme.spacing(2.5)};
  `
);

const ListItemWrapper = styled(ListItemButton)(
    ({ theme }) => `
        &.MuiButtonBase-root {
            margin: ${theme.spacing(1)} 0;
        }
  `
);

const TabsContainerWrapper = styled(Box)(
    ({ theme }) => `
        .MuiTabs-indicator {
            min-height: 4px;
            height: 4px;
            box-shadow: none;
            border: 0;
        }

        .MuiTab-root {
            &.MuiButtonBase-root {
                padding: 0;
                margin-right: ${theme.spacing(1)};
                font-size: ${theme.typography.pxToRem(14)};
                color: ${theme.palette.grey[800]};

                .MuiTouchRipple-root {
                    display: none;
                }
            }

            &.Mui-selected:hover,
            &.Mui-selected {
                color: ${theme.palette.grey[800]};
            }
        }
  `
);

function Thread({ thread, selected, setCurrentThread }) {
    const navigate = useNavigate();

    function handleSelectThread() {
        setCurrentThread(thread);
        navigate(`/dashboard/inbox/${thread.thread_id}`);
    }

    const [unread, setUnread] = useState(0);

    useEffect(() => {
        console.log(`thread: ${thread.thread_id}`);
        fetchUnread();
    });

    async function fetchUnread() {
        try {
            const response = await api.get(`/api/messages/unread/${thread.thread_id}`);
            setUnread(response.data.count);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <List disablePadding component="div" onClick={handleSelectThread}>
            {/* <ListItemWrapper selected> */}
            <ListItemWrapper selected={selected}>
                <ListItemAvatar>
                    {thread.user.profile_picture ? (
                        <Avatar src={`${process.env.REACT_APP_API_URL}/storage/${thread.user.profile_picture}`} />
                    ) : (
                        <Avatar />
                    )}
                </ListItemAvatar>
                <ListItemText
                    sx={{
                        mr: 1,
                    }}
                    primaryTypographyProps={{
                        color: 'textPrimary',
                        variant: 'subtitle2',
                        noWrap: true,
                    }}
                    secondaryTypographyProps={{
                        color: 'textSecondary',
                        noWrap: true,
                    }}
                    primary={`${thread.user.fname} ${thread.user.lname}`}
                    secondary={''}
                />
                {unread > 0 && (
                    <Label color="primary">
                        <b>{unread}</b>
                    </Label>
                )}
            </ListItemWrapper>
        </List>
    );
}

function SidebarContent({ threads, currentThread, setCurrentThread, search, setSearch }) {
    const [state, setState] = useState({
        invisible: true,
    });

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };

    const [currentTab, setCurrentTab] = useState('all');

    const tabs = [
        { value: 'all', label: 'All' },
        { value: 'unread', label: 'Unread' },
        { value: 'archived', label: 'Archived' },
    ];

    const handleTabsChange = (_event, value) => {
        setCurrentTab(value);
    };

    return (
        <RootWrapper>
            {/* <Box display="flex" alignItems="flex-start">
                <Avatar alt={user.name} src={user.avatar} />
                <Box
                    sx={{
                        ml: 1.5,
                        flex: 1,
                    }}
                >
                    <Box display="flex" alignItems="flex-start" justifyContent="space-between">
                        <Box>
                            <Typography variant="h6" noWrap>
                                {user.name}
                            </Typography>
                            <Typography variant="subtitle2" noWrap>
                                {user.jobtitle}
                            </Typography>
                        </Box>
                        <IconButton
                            sx={{
                                p: 1,
                            }}
                            size="small"
                            color="primary"
                        >
                            <SettingsTwoToneIcon fontSize="small" />
                        </IconButton>
                    </Box>

                    <FormControlLabel
                        control={
                            <Switch
                                checked={state.invisible}
                                onChange={handleChange}
                                name="invisible"
                                color="primary"
                            />
                        }
                        label="Invisible"
                    />
                </Box>
            </Box> */}

            <TextField
                sx={{
                    mt: 2,
                    mb: 1,
                }}
                size="small"
                fullWidth
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchTwoToneIcon />
                        </InputAdornment>
                    ),
                }}
                placeholder="Search..."
            />

            <Typography
                sx={{
                    mb: 1,
                    mt: 2,
                }}
                variant="h4"
            >
                Chats
            </Typography>

            <TabsContainerWrapper>
                <Tabs
                    onChange={handleTabsChange}
                    value={currentTab}
                    variant="scrollable"
                    scrollButtons="auto"
                    textColor="primary"
                    indicatorColor="primary"
                >
                    {tabs.map((tab) => (
                        <Tab key={tab.value} label={tab.label} value={tab.value} />
                    ))}
                </Tabs>
            </TabsContainerWrapper>

            <Box mt={2}>
                {currentTab === 'all' &&
                    threads.map((thread, index) => (
                        <Thread
                            key={index}
                            thread={thread}
                            selected={currentThread.thread_id == thread.thread_id}
                            setCurrentThread={setCurrentThread}
                        />
                    ))}
                {currentTab === 'unread' && (
                    <Box pb={3}>
                        <Divider
                            sx={{
                                mb: 3,
                            }}
                        />
                        <AvatarSuccess>
                            <CheckTwoToneIcon />
                        </AvatarSuccess>
                        <Typography
                            sx={{
                                mt: 2,
                                textAlign: 'center',
                            }}
                            variant="subtitle2"
                        >
                            There are no new chats!
                        </Typography>
                        <Divider
                            sx={{
                                mt: 3,
                            }}
                        />
                    </Box>
                )}
                {currentTab === 'archived' && (
                    <Box pb={3}>
                        <Divider
                            sx={{
                                mb: 3,
                            }}
                        />
                        <AvatarSuccess>
                            <CheckTwoToneIcon />
                        </AvatarSuccess>
                        <Typography
                            sx={{
                                mt: 2,
                                textAlign: 'center',
                            }}
                            variant="subtitle2"
                        >
                            Hurray! There are no archived chats!
                        </Typography>
                        <Divider
                            sx={{
                                mt: 3,
                            }}
                        />
                    </Box>
                )}
            </Box>
        </RootWrapper>
    );
}

export default SidebarContent;
