import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import Label from 'components/label';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';
import api from 'services/api';
import Iconify from 'components/iconify/Iconify';
import { useSnackbar } from 'notistack';

export default function UserForm() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    function handleCancel() {
        navigate(-1);
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setLoading(true);

        let formData = Object.fromEntries(new FormData(event.target).entries());
        // console.log(formData);

        let postBody = {
            fname: formData.first_name,
            lname: formData.last_name,
            email: formData.email,
            password: formData.password,
            mobile_no: formData.mobile,
            company: formData.company,
            member_type: formData.member_type,
            role: 'member',
            is_supplier: formData.is_supplier != undefined,
        };

        console.log(postBody);

        try {
            const response = await api.post('/api/users', postBody);
            console.log(response.data);
            navigate(-1);
            enqueueSnackbar(response.data.message, { variant: 'success' });
        } catch (error) {
            console.log(error);
            if (error.response.data.message) {
                enqueueSnackbar(error.response.data.message, { variant: 'error' });
            } else {
                enqueueSnackbar('Invalid', { variant: 'error' });
            }
        }

        setLoading(false);
    }

    return (
        <Card>
            <form onSubmit={handleSubmit}>
                <CardHeader title="Create Member" />
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Stack>
                                <InputLabel>First Name</InputLabel>
                                <TextField
                                    id="first_name_input"
                                    name="first_name"
                                    placeholder="First Name"
                                    variant="outlined"
                                    required
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack>
                                <InputLabel>Last Name</InputLabel>
                                <TextField
                                    id="last_name_input"
                                    name="last_name"
                                    placeholder="Last Name"
                                    variant="outlined"
                                    required
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack>
                                <InputLabel>Email</InputLabel>
                                <TextField
                                    id="email_input"
                                    name="email"
                                    placeholder="Email"
                                    variant="outlined"
                                    required
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack>
                                <InputLabel>Mobile Number</InputLabel>
                                <TextField
                                    id="mobile_input"
                                    name="mobile"
                                    placeholder="Mobile Number"
                                    variant="outlined"
                                    required
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack>
                                <InputLabel>Company Name</InputLabel>
                                <TextField
                                    id="company_input"
                                    name="company"
                                    placeholder="Company Name"
                                    variant="outlined"
                                    required
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <Stack>
                                    <Typography color="text.secondary">Member Type</Typography>
                                    <Select
                                        id="demo-simple-select"
                                        name="member_type"
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        displayEmpty
                                        defaultValue="regular"
                                    >
                                        <MenuItem value="regular">Regular Member</MenuItem>
                                        <MenuItem value="affiliate">Affiliate Member</MenuItem>
                                    </Select>
                                </Stack>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack>
                                <InputLabel>Temporary Password</InputLabel>
                                <TextField
                                    id="password_input"
                                    name="password"
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Temporary Password"
                                    variant="outlined"
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                    <Iconify
                                                        icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControlLabel control={<Checkbox name="is_supplier" />} label="Supplier" />
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions sx={{ p: 3 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Button variant="outlined" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <LoadingButton
                            type="submit"
                            loading={loading}
                            disabled={loading}
                            variant="contained"
                            loadingPosition="start"
                            startIcon={<AddIcon />}
                        >
                            Add
                        </LoadingButton>
                    </Box>
                </CardActions>
            </form>
        </Card>
    );
}
