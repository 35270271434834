import { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Typography,
    Card,
    Tooltip,
    Avatar,
    CardMedia,
    Button,
    IconButton,
    styled,
    Skeleton,
    Chip,
} from '@mui/material';

import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';
import UploadTwoToneIcon from '@mui/icons-material/UploadTwoTone';
import MoreHorizTwoToneIcon from '@mui/icons-material/MoreHorizTwoTone';
import { useSelector } from 'react-redux';
import api from 'services/api';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import MessageModal from './MessageModal';

const Input = styled('input')({
    display: 'none',
});

const AvatarWrapper = styled(Card)(
    ({ theme }) => `

    position: relative;
    overflow: visible;
    display: inline-block;
    margin-top: -${theme.spacing(9)};
    margin-left: ${theme.spacing(2)};

    .MuiAvatar-root {
      width: ${theme.spacing(16)};
      height: ${theme.spacing(16)};
    }
`
);

const ButtonUploadWrapper = styled(Box)(
    ({ theme }) => `
    position: absolute;
    width: ${theme.spacing(4)};
    height: ${theme.spacing(4)};
    bottom: -${theme.spacing(1)};
    right: -${theme.spacing(1)};

    .MuiIconButton-root {
      border-radius: 100%;
      background: ${theme.palette.primary.main};
      color: ${theme.palette.primary.contrastText};
      box-shadow: ${theme.shadows[1]};
      width: ${theme.spacing(4)};
      height: ${theme.spacing(4)};
      padding: 0;
  
      &:hover {
        background: ${theme.palette.primary.dark};
      }
    }
`
);

const CardCover = styled(Card)(
    ({ theme }) => `
    position: relative;

    .MuiCardMedia-root {
      height: ${theme.spacing(26)};
    }
`
);

const CardCoverAction = styled(Box)(
    ({ theme }) => `
    position: absolute;
    right: ${theme.spacing(2)};
    bottom: ${theme.spacing(2)};
`
);

// const user = {
//     savedCards: 7,
//     name: 'Juan Dela Cruz',
//     coverImg: '/assets/images/covers/cover_1.jpg',
//     avatar: '/assets/images/avatars/avatar_default.jpg',
//     description:
//         "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage",
//     jobtitle: 'Real Estate Manager',
//     location: 'Barcelona, Spain',
//     followers: '465',
// };

const ProfileCover = ({ user, fetchUser }) => {
    const fullName = `${user?.fname ?? ''} ${user?.lname ?? ''}`;

    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();

    const { currentUser } = useSelector((state) => state.currentUser);

    const [openMessage, setOpenMessage] = useState(false);

    async function handleUploadCover(event) {
        console.log(event.target.files[0]);

        const form = new FormData();

        form.append('profile_banner', event.target.files[0]);

        try {
            const response = await api.upload('/api/auth/update-user-profile-banner', form);
            console.log(response.data);
            enqueueSnackbar(response.data.message, { variant: 'success' });
            fetchUser();
        } catch (error) {
            console.log(error);
            enqueueSnackbar('Invalid file.', { variant: 'error' });
        }
    }

    async function handleUploadProfile(event) {
        console.log(event.target.files[0]);

        const form = new FormData();

        form.append('profile_picture', event.target.files[0]);

        try {
            const response = await api.upload('/api/auth/update-user-profile-picture', form);
            console.log(response.data);
            enqueueSnackbar(response.data.message, { variant: 'success' });
            fetchUser();
        } catch (error) {
            console.log(error);
            enqueueSnackbar('Invalid file.', { variant: 'error' });
        }
    }

    function handleEditInfo() {
        navigate('/dashboard/profile/edit');
    }

    async function handleSendMessage() {
        try {
            const response = await api.get(`/api/messages/recipient/${user.id}`);
            navigate(`/dashboard/inbox/${response.data[0].id}`);
            return;
        } catch (error) {
            console.log(error);
        }

        // show new message modal
        setOpenMessage(true);
    }

    const connectionStatus = checkConnection(user?.friends ?? []);

    function checkConnection(friends) {
        if (friends) {
            for (let i = 0; i < friends.length; i++) {
                const friend = friends[i];

                if (friend.id == currentUser.id) {
                    return friend.pivot.confirmed ? 'connected' : 'pending';
                }
            }
        }

        return 'not_connected';
    }

    const [loading, setLoading] = useState(false);

    async function handleConnect() {
        setLoading(true);

        try {
            const response = await api.post(`/api/users/connect/${user.id}`);
            await fetchUser();
            enqueueSnackbar(response.data.message, { variant: 'success' });
        } catch (error) {
            console.log(error);
            enqueueSnackbar('Something went wrong. Please try again later.', { variant: 'error' });
        }

        setLoading(false);
    }

    return (
        <>
            {/* <Box display="flex" mb={3}>
                <Tooltip arrow placement="top" title="Go back">
                    <IconButton color="primary" sx={{ p: 2, mr: 2 }}>
                        <ArrowBackTwoToneIcon />
                    </IconButton>
                </Tooltip>
                <Box>
                    <Typography variant="h3" component="h3" gutterBottom>
                        Profile for {user.name}
                    </Typography>
                    <Typography variant="subtitle2">
                        This is a profile page. Easy to modify, always blazing fast
                    </Typography>
                </Box>
            </Box> */}
            {user && <MessageModal open={openMessage} setOpen={setOpenMessage} recipient={user} />}
            <CardCover>
                {user ? (
                    <>
                        <CardMedia
                            image={
                                user.profile_banner
                                    ? `${process.env.REACT_APP_API_URL}/storage/${user.profile_banner}`
                                    : '/assets/images/covers/cover_default.jpg'
                            }
                        />
                        {currentUser.id == user.id && (
                            <CardCoverAction>
                                <Input onChange={handleUploadCover} accept="image/*" id="change-cover" type="file" />
                                <label htmlFor="change-cover">
                                    <Button startIcon={<UploadTwoToneIcon />} variant="contained" component="span">
                                        Change cover
                                    </Button>
                                </label>
                            </CardCoverAction>
                        )}
                    </>
                ) : (
                    <Skeleton variant="rounded" height={200} />
                )}
            </CardCover>
            <AvatarWrapper>
                {user ? (
                    <>
                        <Avatar
                            variant="rounded"
                            alt={fullName}
                            src={`${process.env.REACT_APP_API_URL}/storage/${user.profile_picture}`}
                        />
                        {currentUser.id == user.id && (
                            <ButtonUploadWrapper>
                                <Input
                                    onChange={handleUploadProfile}
                                    accept="image/*"
                                    id="icon-button-file"
                                    name="icon-button-file"
                                    type="file"
                                />
                                <label htmlFor="icon-button-file">
                                    <IconButton component="span" color="primary">
                                        <UploadTwoToneIcon />
                                    </IconButton>
                                </label>
                            </ButtonUploadWrapper>
                        )}
                    </>
                ) : (
                    <Skeleton variant="rounded">
                        <Avatar variant="rounded" />
                    </Skeleton>
                )}
            </AvatarWrapper>
            <Box py={2} pl={2}>
                <Typography gutterBottom variant="h4">
                    {user ? fullName : <Skeleton width={200} />}
                </Typography>
                {/* <Typography variant="subtitle2" color="text.secondary">{user.description}</Typography> */}
                <Typography sx={{ py: 2 }} variant="subtitle2" color="text.primary">
                    {user ? `${user.company} | ${user.email} | ${user.member_type} member` : <Skeleton width={400} />}
                </Typography>
                <Box display={{ xs: 'block', md: 'flex' }} alignItems="center" justifyContent="space-between">
                    {user ? (
                        <Box>
                            {currentUser.id == user.id && (
                                <Button onClick={handleEditInfo} size="small" sx={{ mr: 1 }} variant="outlined">
                                    Edit My Info
                                </Button>
                            )}
                            {connectionStatus == 'connected' && currentUser.id != user.id && (
                                <Button onClick={handleSendMessage} size="small" variant="contained">
                                    Message
                                </Button>
                            )}
                            {connectionStatus == 'not_connected' && currentUser.id != user.id && (
                                <LoadingButton
                                    loading={loading}
                                    loadingPosition="start"
                                    onClick={handleConnect}
                                    size="small"
                                    variant="outlined"
                                    startIcon={<AddIcon />}
                                >
                                    Connect
                                </LoadingButton>
                            )}
                            {connectionStatus == 'pending' && currentUser.id != user.id && (
                                <Chip label="Pending Approval" color="warning" variant="outlined" />
                            )}
                            {/* <IconButton color="primary" sx={{ p: 0.5 }}>
                                <MoreHorizTwoToneIcon />
                            </IconButton> */}
                        </Box>
                    ) : (
                        <Skeleton width={80} />
                    )}

                    {/* <Button
                        sx={{ mt: { xs: 2, md: 0 } }}
                        size="small"
                        variant="text"
                        endIcon={<ArrowForwardTwoToneIcon />}
                    >
                        See all connections
                    </Button> */}
                </Box>
            </Box>
        </>
    );
};

// ProfileCover.propTypes = {
//   // @ts-ignore
//   user: PropTypes.object.isRequired
// };

export default ProfileCover;
