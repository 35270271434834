import React, { useCallback } from 'react';
import { useHelpers, useRemirrorContext } from '@remirror/react';
import { WysiwygEditor } from '@remirror/react-editors/wysiwyg';
import { Box, Button } from '@mui/material';

const delay = ms => new Promise(r => setTimeout(r, ms));

const SAMPLE_DOC = {
    type: 'doc',
    content: [
        {
            type: 'paragraph',
            attrs: { dir: null, ignoreBidiAutoUpdate: null },
            content: [{ type: 'text', text: 'Loaded content' }],
        },
    ],
};

function LoadButton() {
    const { setContent } = useRemirrorContext();
    const handleClick = useCallback(() => setContent(SAMPLE_DOC), [setContent]);

    return (
        <button onMouseDown={(event) => event.preventDefault()} onClick={handleClick}>
            Load
        </button>
    );
}

function SaveButton({ setContent }) {
    const { getJSON } = useHelpers();

    const handleClick = useCallback(() => {
        // alert(JSON.stringify(getJSON()));
        const content = JSON.stringify(getJSON());
        setContent(content);
    }, [getJSON]);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 2 }}>
            <Button variant="outlined" onMouseDown={(event) => event.preventDefault()} onClick={handleClick}>
                Save
            </Button>
        </Box>
    );
}

const Rte = ({ setContent }) => {
    return (
        <WysiwygEditor placeholder="Start typing...">
            {/* <LoadButton /> */}
            <SaveButton setContent={setContent} />
        </WysiwygEditor>
    );
};

export default Rte;
