import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import { useSupplierList } from 'data/suppliers';
// @mui
import { Container, Stack, Typography } from '@mui/material';
// components
import {
    ProductSort,
    ProductList,
    ProductCartWidget,
    ProductFilterSidebar,
    ProductSearch,
    EmptyCard
} from '../sections/@dashboard/members';
// mock
// import SUPPLIERS from '../_mock/suppliers';

// ----------------------------------------------------------------------

export default function SuppliersPage() {

    const [sort, setSort] = useState('desc');

    const [openSort, setOpenSort] = useState(null);

    const SUPPLIERS = useSupplierList(sort);

    function handleSelectSort(option) {
        setSort(option.value);
        setOpenSort(null);
    }

    return (
        <>
            <Helmet>
                <title> Go Virtual Members: Suppliers </title>
            </Helmet>

            <Container>
                <Typography variant="h4" sx={{ mb: 5 }}>
                    Supplier Finder
                </Typography>

                <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
                    <ProductSearch members={SUPPLIERS} />
                    <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
                        <ProductSort sort={sort} open={openSort} setOpen={setOpenSort} onSelect={handleSelectSort} />
                    </Stack>
                </Stack>

                {SUPPLIERS?.length > 0 ?
                    <ProductList products={SUPPLIERS} />
                    :
                    <EmptyCard />
                }
            </Container>
        </>
    );
}
