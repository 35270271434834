import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { faker } from '@faker-js/faker';
import dayjs from 'dayjs';
import { titleCase } from 'utils/titleCase';
import { ArrowBackIosNew } from '@mui/icons-material';
import api from 'services/api';
import ReactQuill from 'react-quill';
import { useSelector } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';

function ConfirmDelete({ open, setOpen, post }) {
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    function handleClose() {
        setOpen(false);
    }

    async function handleConfirm() {
        setLoading(true);

        try {
            const response = await api.destroy(`/api/posts/${post.id}`);
            enqueueSnackbar(response.data.message, { variant: 'success' });
            navigate('/dashboard/home');
        } catch (error) {
            enqueueSnackbar('Something went wrong. Please try again later.', { variant: 'error' });
            console.log(error);
        }

        setLoading(false);
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{'Delete Confirmation'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this post?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <LoadingButton loading={loading} onClick={handleConfirm} color="error" variant="contained">
                        Delete
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default function PostView() {
    const { slug } = useParams();

    const { currentUser } = useSelector((state) => state.currentUser);

    const navigate = useNavigate();

    const [post, setPost] = useState();

    const [openDelete, setOpenDelete] = useState(false);

    function handleBack() {
        navigate(-1);
    }

    useEffect(() => {
        console.log('slug', slug);
        console.log('date', dayjs().format());

        fetchPost();
    }, []);

    async function fetchPost() {
        try {
            const response = await api.get(`/api/posts/${slug}`);

            console.log(response.data);
            setPost(response.data.post);
        } catch (error) {
            console.log(error);
        }
    }

    function handleEdit() {
        navigate(`/dashboard/post/edit/${slug}`);
    }

    return (
        <>
            <ConfirmDelete open={openDelete} setOpen={setOpenDelete} post={post} />

            <Helmet>
                <title> Go Virtual Members - Post View </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="flex-start" mb={5}>
                    <IconButton color="primary" onClick={handleBack}>
                        <ArrowBackIosNew />
                    </IconButton>
                    <Typography variant="h4" sx={{ textTransform: 'capitalize' }}>
                        {post?.category ?? ''}
                    </Typography>
                </Stack>

                {/* <Typography variant="h4" gutterBottom sx={{ mb: 5, textTransform: 'capitalize' }}>
                    {post?.title ?? ''}
                </Typography> */}
                <Card>
                    <div style={{ height: 500, overflow: 'hidden' }}>
                        <CardMedia
                            sx={{ height: '100%', filter: 'blur(10px)', WebkitFilter: 'blur(10px)' }}
                            image={`${process.env.REACT_APP_API_URL}/storage/${post?.image}`}
                            title="blurred-cover"
                        />
                    </div>
                    {post && (
                        <img
                            src={`${process.env.REACT_APP_API_URL}/storage/${post?.image}`}
                            alt="cover"
                            style={{
                                height: 500,
                                zIndex: 2,
                                position: 'absolute',
                                top: 0,
                                left: '50%',
                                transform: 'translate(-50%, 0)',
                            }}
                        />
                    )}
                    <CardContent>
                        <Typography variant="h5" component="div" sx={{ textTransform: 'capitalize', marginLeft: 2 }}>
                            {post?.title ?? ''}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ marginLeft: 2 }} gutterBottom>
                            {dayjs(post?.created_at).format('LLL')}
                        </Typography>
                        <ReactQuill value={post?.content} readOnly theme={'bubble'} />
                    </CardContent>
                    {currentUser.role == 'admin' && (
                        <CardActions sx={{ p: 3 }}>
                            <Button onClick={handleEdit} variant="outlined">
                                Edit
                            </Button>
                            <Button onClick={() => setOpenDelete(true)} variant="outlined" color="error">
                                Delete
                            </Button>
                        </CardActions>
                    )}
                </Card>
            </Container>
        </>
    );
}
