import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import Rte from 'components/rte';
import { useNavigate } from 'react-router-dom';
import FileUpload from 'react-mui-fileuploader';
import { EditorComponent, Remirror, useRemirror } from '@remirror/react';
import { UploadFile } from '@mui/icons-material';
import api from 'services/api';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import ReactQuillEditor from 'components/rte/ReactQuillEditor';

const delay = (ms) => new Promise((r) => setTimeout(r, ms));

export default function BlogPostForm() {
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState();
    const [preview, setPreview] = useState();

    const [title, setTitle] = useState('');
    const [category, setCategory] = useState('blog');

    const [content, setContent] = useState('');

    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);

    function handleCancel() {
        navigate(-1);
    }

    async function handleSubmit(event) {
        event.preventDefault();
        // console.log(content);

        setLoading(true);

        const form = new FormData();

        form.append('image', selectedFile);
        form.append('content', content);
        form.append('title', title);
        form.append('category', category);
        form.append('is_event', 0);
        form.append('is_featured', 0);

        console.log([...form]);

        try {
            const response = await api.upload('/api/posts', form);
            enqueueSnackbar(response.data.message, { variant: 'success' });
            await delay(500);
            navigate(-1);
        } catch (error) {
            console.log(error);

            if (error.response.data.content) {
                enqueueSnackbar(error.response.data.content, { variant: 'error' });
            }
            if (error.response.data.image) {
                error.response.data.image.forEach((errorMsg) => {
                    enqueueSnackbar(errorMsg, { variant: 'error' });
                });
            }
        }

        setLoading(false);
    }

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined);
            return;
        }

        const objectUrl = URL.createObjectURL(selectedFile);
        setPreview(objectUrl);

        // free memory when ever this component is unmounted
        // eslint-disable-next-line consistent-return
        return () => URL.revokeObjectURL(objectUrl);
    }, [selectedFile]);

    function onSelectFile(e) {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined);
            return;
        }

        // I've kept this example simple by using the first image instead of multiple
        setSelectedFile(e.target.files[0]);

        console.log(e.target.files[0]);
    }

    return (
        <Card>
            <CardHeader title="Create a new post" />
            <form onSubmit={handleSubmit}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Stack>
                                <InputLabel>Title</InputLabel>
                                <TextField
                                    id="title_input"
                                    placeholder="Title"
                                    variant="outlined"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    required
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Stack>
                                    <Typography color="text.secondary">Category</Typography>
                                    <Select
                                        id="demo-simple-select"
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        displayEmpty
                                        value={category}
                                        onChange={(e) => setCategory(e.target.value)}
                                    >
                                        <MenuItem value="blog">Blog</MenuItem>
                                        <MenuItem value="article">Article</MenuItem>
                                    </Select>
                                </Stack>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            {selectedFile && (
                                <Box
                                    sx={{
                                        overflow: 'hidden',
                                        borderRadius: 1,
                                        marginBottom: 2,
                                        width: 'fit-content',
                                    }}
                                >
                                    <img
                                        src={preview}
                                        alt="upload"
                                        style={{
                                            height: 300,
                                            objectFit: 'contain',
                                        }}
                                    />
                                </Box>
                            )}
                            <Button variant="outlined" startIcon={<UploadFile />} component="label">
                                Upload
                                <input type="file" accept="image/*" hidden onChange={onSelectFile} />
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel>Content</InputLabel>
                            <ReactQuillEditor content={content} setContent={setContent} />
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions sx={{ p: 3 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Button variant="outlined" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <LoadingButton loading={loading} variant="contained" type="submit">
                            Submit
                        </LoadingButton>
                    </Box>
                </CardActions>
            </form>
        </Card>
    );
}
