import PropTypes from 'prop-types';

// @mui
import { styled } from '@mui/material/styles';
import { Autocomplete, InputAdornment, Popper, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledPopper = styled((props) => <Popper placement="bottom-start" {...props} />)({
    width: '280px !important',
});

// ----------------------------------------------------------------------

ProductSearch.propTypes = {
    members: PropTypes.array.isRequired,
};

export default function ProductSearch({ members }) {

    const navigate = useNavigate();

    function handleChange(event, value) {
        navigate(`/dashboard/members/${value.id}`);
    }

    return (
        <Autocomplete
            sx={{ width: 280 }}
            autoHighlight
            popupIcon={null}
            PopperComponent={StyledPopper}
            options={members}
            getOptionLabel={(member) => member.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder="Search..."
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <Iconify
                                    icon={'eva:search-fill'}
                                    sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }}
                                />
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            onChange={handleChange}
        />
    );
}
